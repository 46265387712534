import React, { PureComponent } from 'react';
import { Button, Modal, message } from 'antd';

import { withTranslate } from '../../contexts/localContext';
import LoadingPanel from '../../components/loader';
import GridDropdown from '../../components/GridDropdown/GridDropdown';
import { ApiService } from '../../services';

import './PayerPhoneAndCardNumberGrid.styled.scss';

const api = new ApiService();

class AddServiceDialog extends PureComponent {
  state = {
    isLoading: false,
    selectedServices: [],
    servicesList: [],
  };

  componentDidMount() {
    this.fetchServices();
  }

  addServices = async () => {
    const { translate, closeDialog, onRefresh } = this.props;
    const { selectedServices } = this.state;

    try {
      await this.setState({ isLoading: true });
      await api.addGatewayRuleException({
        serviceIds: selectedServices,
      });
      this.setState({
        isLoading: false,
      });
      message.success(translate('page.payerPhoneAndCardNumber.addSuccessfully'), 2);
      onRefresh();
      closeDialog();
    } catch (error) {
      const { showError } = this.props;
      showError(error);
      await this.setState({ isLoading: false });
    }
  };

  fetchServices = async () => {
    try {
      await this.setState({ isLoading: true });
      const servicesList = await api.getServicesByGateWay();
      this.setState({
        isLoading: false,
        servicesList:
          servicesList && servicesList.length
            ? servicesList.map((service) => ({ ...service, Id: service.ServiceId, Name: service.ServiceName }))
            : [],
      });
    } catch (error) {
      const { showError } = this.props;
      showError(error);
      await this.setState({ isLoading: false });
    }
  };

  render() {
    const { translate, closeDialog, visible } = this.props;
    const { isLoading, selectedServices, servicesList } = this.state;

    return (
      <Modal
        className="PayerPhoneAndCardNumberGrid-dialog"
        title={translate('page.payerPhoneAndCardNumber.addRules')}
        visible={visible}
        onCancel={isLoading ? () => {} : closeDialog}
        footer={[
          <Button type="primary" loading={isLoading} onClick={this.addServices} disabled={!selectedServices.length}>
            {translate('core.buttonTitles.add')}
          </Button>,
          <Button onClick={closeDialog}>{translate('core.buttonTitles.refuse')}</Button>,
        ]}
      >
        <div className="PayerPhoneAndCardNumberGrid-row">
          <div className="PayerPhoneAndCardNumberGrid-label">{translate('page.additionalServiceProps.col-name')}</div>
          <div className="PayerPhoneAndCardNumberGrid-select">
            <GridDropdown
              data={servicesList}
              colConfig={[
                {
                  fieldName: 'ServiceId',
                  titleTranslateSlug: 'grids.servicesBigTable.column-serviceId',
                  width: '110',
                  filter: 'numeric',
                  isShowOnMobile: true,
                },
                {
                  fieldName: 'ServiceName',
                  titleTranslateSlug: 'page.commissionsServices.col-serviceName',
                  width: '200',
                  isShowOnMobile: true,
                },
                {
                  fieldName: 'F108',
                  titleTranslateSlug: 'page.OTPServices.col-NBU',
                  width: '100',
                },
              ]}
              selectItems={selectedServices}
              onSave={(selectedServices) => {
                this.setState({ selectedServices });
              }}
              defaultTitle={translate('page.commissionsServices.chooseService')}
            />
          </div>
        </div>
        {isLoading && <LoadingPanel />}
      </Modal>
    );
  }
}

export default withTranslate(AddServiceDialog);
