export const ALL_COLUMNS_GRID = [
  'supplierName',
  'groupName',
  'serviceId',
  'name',
  'transitService',
  'statusName',
  'refId',
  'managerName',
  'supportManagerName',
  'salesManagerName',
];
