import React, { PureComponent } from 'react';
import { Button, Col, Select, Input, Row } from 'antd';
import { GridColumn } from '@progress/kendo-react-grid';
import Grid from '../../components/grids/baseGrid';
import { withTranslate } from '../../contexts/localContext';
import { SEARCH_TYPES } from './constants';
import { ApiService, tabService } from '../../services';
import { getTransformDateString } from '../../utils/getTransformDateString';
import findArray from '../../utils/findArrForCurrentPage';
import './ClientCabinetStyle.scss';
import ChangeClientDataModal from './ChangeClientDataModal';
import checkElement from '../../utils/checkElement';
const { Option } = Select;
const api = new ApiService();

class ClientCabinetGrid extends PureComponent {
  currentArray = findArray('clientCabinetFinmon');

  state = {
    isLoading: false,
    clientList: [],
    searchType: undefined,
    searchQuery: undefined,
    errorMessage: '',
    selectedItem: [],
    openModal: false,
  };

  fetchClient = async () => {
    const { searchType, searchQuery } = this.state;
    this.setState({ isLoading: true });
    try {
      const model = {
        [searchType]: searchQuery,
      };

      const clientListData = await api.getClients(model);

      if (!clientListData) {
        this.setState({ isLoading: false });
        return;
      }

      this.setState({
        clientList: this.normalizeData(clientListData),
      });
    } catch (error) {
      const { showError } = this.props;
      showError(error);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  normalizeData = (data) => {
    if (!data || !data.length) {
      return [];
    }

    return data.map((dataItem) => ({
      ...dataItem,
      transactionPropertyKey: this.state.searchType,
      transactionPropertyValue: this.state.searchQuery,
    }));
  };

  buildToolbar = () => {
    const { translate } = this.props;
    const { searchType, searchQuery, selectedItem } = this.state;

    return (
      <Row>
        <Col className="EasyPayUsers-toolbar">
          <div className="EasyPayUsers-fieldRow">
            <div className="EasyPayUsers-label">{translate('page.clientCabinet.searchType')}:</div>
            <Select
              value={searchType}
              placeholder={translate('page.easyPayUsers.selectSearchType')}
              className="EasyPayUsers-select"
              onChange={(searchType) => {
                this.setState({ searchType, siteList: [] });
              }}
              allowClear
            >
              {SEARCH_TYPES.map(({ translateTitle, title, id }) => (
                <Option value={id} key={id}>
                  {title || translate(translateTitle)}
                </Option>
              ))}
            </Select>
          </div>

          <div className="EasyPayUsers-fieldRow">
            <div className="EasyPayUsers-shortLabel">{translate('page.easyPayUsers.value')}:</div>
            <Input
              placeholder={translate('page.easyPayUsers.query')}
              className="EasyPayUsers-input"
              onChange={({ target: { value: searchQuery } }) => this.setState({ searchQuery, siteList: [] })}
            />
          </div>
          <Button type="primary" disabled={!searchQuery || !searchType} onClick={this.fetchClient}>
            {translate('core.buttonTitles.search')}
          </Button>
        </Col>
        <Col className="clientCabinet-toolbar">
         {checkElement('clientCabinetFinmon-сhangeClientData', this.currentArray) && <Button
            type="primary"
            disabled={selectedItem.length === 0}
            onClick={() => {
              this.setState({ openModal: true });
            }}
          >
            {translate('page.clientCabinet.changeClientData')}
          </Button>}

         {checkElement('clientCabinetFinmon-operationHistory', this.currentArray) && <Button
            type="primary"
            disabled={selectedItem.length === 0}
            onClick={() => {
              tabService.addTab({ type: 'operationHistory', user: selectedItem[0] });
            }}
          >
            {translate('page.clientCabinet.operationHistory')}
          </Button>}
        </Col>
      </Row>
    );
  };

  handleSelectionChange = (selectedItem) => {
    this.setState({ selectedItem });
  };

  render() {
    const { clientList, isLoading, openModal, selectedItem } = this.state;
    if (!checkElement('clientCabinetFinmon-content', this.currentArray)) {
      return null;
    }

    return (
      <>
        <Grid
          data={clientList}
          isLoading={isLoading}
          name="clientCabinetGrid"
          toolbar={this.buildToolbar()}
          onRefresh={this.fetchClient}
          fieldForSelection="IdentificationId"
          onSelect={this.handleSelectionChange}
        >
          <GridColumn field="selected" width="50px" filterable={false} sortable={false} columnMenu={false} />
          <GridColumn field="Name" title="page.serviceSupplier.fullName" width="290" />
          <GridColumn field="Phone" title="page.serviceSupplier.phone" width="190" />
          <GridColumn
            field="Birthday"
            title="grids.search-lists-sanctioners.column-dateBd"
            width="160"
            cell={({ dataItem: { Birthday } }) => (
              <td style={{ textAlign: 'center' }}>{getTransformDateString(Birthday,'DD.MM.YYYY')}</td>
            )}
            formatFilterCellData={(Birthday) => getTransformDateString(Birthday,'DD.MM.YYYY')}
          />
          <GridColumn field="Email" title="page.serviceSupplier.col-email" width="140" />
          <GridColumn field="VerificationSource" title="page.clientCabinet.VerificationSource" width="150" />
          <GridColumn
            field="VerificationDate"
            title="page.clientCabinet.VerificationDate"
            width="150"
            cell={({ dataItem: { VerificationDate } }) => (
              <td style={{ textAlign: 'center' }}>{getTransformDateString(VerificationDate)}</td>
            )}
            formatFilterCellData={(VerificationDate) => getTransformDateString(VerificationDate)}
          />
          <GridColumn
            field="VerificationExpirationDate"
            title="page.clientCabinet.VerificationExpirationDate"
            width="150"
            cell={({ dataItem: { VerificationExpirationDate } }) => (
              <td style={{ textAlign: 'center' }}>{getTransformDateString(VerificationExpirationDate)}</td>
            )}
            formatFilterCellData={(VerificationExpirationDate) => getTransformDateString(VerificationExpirationDate)}
          />
          <GridColumn field="Status" title="page.terminalSettings.field-status" width="190" />
          <GridColumn field="UserGuid" title="page.profilesMenu.userId" width="130" />
          <GridColumn field="TaxNumber" title="page.beneficiaries.inn" width="130" />
          <GridColumn field="IdentificationId" title="Id" width="130" />
        </Grid>
        {openModal && (
          <ChangeClientDataModal
            openModal={openModal}
            closeModal={() => {
              this.setState({ openModal: false });
            }}
            user={selectedItem[0]}
          />
        )}
      </>
    );
  }
}

export default withTranslate(ClientCabinetGrid);
