import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Grid } from '@progress/kendo-react-grid';
import './desktop.scss';
import { process } from '@progress/kendo-data-query';
import { formatDate } from '@telerik/kendo-intl';
import { withTranslate } from '../../../contexts/localContext';

class DesktopGrid extends PureComponent {
  static propTypes = {
    data: PropTypes.arrayOf(PropTypes.any).isRequired,
  };

  wrapper = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      height: '100%',
      dataState: {
        group: [],
      },
      skip: 0,
      expandetField: [],
    };
  }

  onResize = () => {
    //  I'm Gonna Show You Crazy
    const div = this.wrapper.current;
    this.setState({ height: div.offsetHeight });
  };

  pageChange = (event) => {
    const { handleGridSkipChange } = this.props;

    handleGridSkipChange(isNaN(event.page.skip) ? 0 : event.page.skip);
  };

  flattenDataByExpandedAllData = (data) => {
    //Метод що робить одномірний масив обектів
    const result = [];
    const { expandetField } = this.state;

    const processItems = (items, parentIndex = 7000) => {
      items.forEach((item, ind) => {
        // проходимося по обектам
        if (!item.hasOwnProperty('field')) {
          // якщо у обекта немає ключа field то це не обект групи а звичайний дата обект, просто закидуємо його в масив и робимо ретурн( бо айтемів далі точно не буде)
          result.push(item);
          return;
        } else {
          // якщо field є то це обект групи, додаемо до цього обекта ключ rowType він нам знадобиться при рендері ( якщо і робити звязку група і його айтеми то це тут або при рендері роу), ідемо далі
          item.rowType = 'groupHeader';
          item.groupId = `${item.field}-${ind}-${parentIndex}`;
          item.count = item.items.length;
          if (expandetField.includes(item.groupId)) {
            item.items = [];
          }
          result.push(item);
        }

        if (item.items && item.items.length > 0) {
          // якщо у обекта є масив айтемс і він не пустий то рекурсивно вкиликаємо до цього масиву метод processItems
          processItems(item.items, ind);
        }
      });
    };

    processItems(data);
    return result;
  };

  rowRender = (element, props) => {
    const { translate, columnTree, rowRender, groupTree } = this.props;
    const { expandetField } = this.state;
    const groupData = groupTree //groupData це масив обєктів груп відсортовані по orderIndex ( тобто вложеність груп)
      .filter((item) => item.locked)
      .sort((a, b) => a.orderIndex - b.orderIndex)
      .map((item, index) => ({
        field: item.field,
        order: index,
      }));
    if (props.dataItem.rowType === 'groupHeader') {
      // тут якщо dataItem має значення  rowType = groupHeader то робимо рендер рядка групи
      const renderFieldOrder = groupData.find((item) => item.field === props.dataItem.field).order;
      const renderFieldTitle = columnTree.find((item) => item.field === props.dataItem.field).text;
      let renderData = props.dataItem.value;
      if (renderData instanceof Date && !isNaN(renderData.getTime())) {
        renderData = formatDate(renderData, 'dd.MM.yyyy hh:mm:ss');
      }

      const colaps = expandetField.includes(props.dataItem.groupId);


      return (
        <tr className={`k-master-row k-alt groupTr groupRowOrder${renderFieldOrder}`}>
          <td colSpan={props.children.length}>
            <div className="groupWrapper">
              <button
                className="k-button"
                onClick={() => {
                  this.setState({
                    expandetField: expandetField.includes(props.dataItem.groupId)
                      ? expandetField.filter((item) => item !== props.dataItem.groupId)
                      : [...expandetField, props.dataItem.groupId],
                  });
                }}
              >
                <span className={`k-icon ${!colaps ? 'k-i-arrow-s' : 'k-i-arrow-n'}`}></span>
              </button>
              <span className="groupTitle">{translate(renderFieldTitle)}:</span>
              <span className="groupTitle">{renderData}</span>
              <span className="groupTitle">{`(${translate('base-grid.amountRows')}: ${
                props.dataItem.count
              })`}</span>
            </div>
          </td>
        </tr>
      );
    }

    const { className, children } = element.props;
    const returnedElement = React.cloneElement(element, { className }, children);
    const decoratedElementByRowRenderFromProps = rowRender ? rowRender(returnedElement, props) : element;
    return decoratedElementByRowRenderFromProps;
  };

  render() {
    const { height, dataState } = this.state;
    const { groupTree, data, style, children, rowCount, rowHeight, gridSkip, ...props } = this.props;
    const dataStateRender = {
      ...dataState,
      group: groupTree //group це масив обєктів груп відсортовані по orderIndex ( тобто вложеність груп)
        .filter((item) => item.locked)
        .map((item) => ({
          field: item.field,
          order: item.orderIndex,
        }))
        .sort((a, b) => a.order - b.order),
    };

    console.log('data',data.slice(0,2))

    return (
      <div ref={this.wrapper} style={{ height: '100%' }}>
        <Grid
          {...props}
          style={{ height: `${height}px`, ...style }}
          rowHeight={rowHeight}
          data={this.flattenDataByExpandedAllData(process(data, dataStateRender).data).slice(
            //process(data, dataStateRender).data якраз і робить групування на основі dataStateRender в якому є ключ group значення якго масив обєктів груп [{field:string,orderIndex:number}]
            gridSkip,
            gridSkip + rowCount,
          )}
          pageSize={rowCount}
          total={this.flattenDataByExpandedAllData(process(data, dataStateRender).data).length}
          skip={gridSkip}
          scrollable="virtual"
          onPageChange={this.pageChange}
          reorderable
          rowRender={this.rowRender}
        >
          {children}
        </Grid>
      </div>
    );
  }
}

export default withTranslate(DesktopGrid);
