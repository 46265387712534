import React, { PureComponent } from 'react';
import { Button, Input, Modal, Select } from 'antd';

import { withTranslate } from '../../contexts/localContext';

import { ApiService } from '../../services';
import LoadingPanel from '../../components/loader';
import { DatePicker } from '@progress/kendo-react-dateinputs';

const { Option } = Select;
const api = new ApiService();

class ChangeClientDataModal extends PureComponent {
  state = {
    isLoading: false,
    transactionsProps: [],
    selectedTransactionProps: [],
  };

  fetchAllTransactionsProps = async () => {
    try {
      await this.setState({ isLoading: true });

      const { userGuid, userId } = this.props;

      const modal = {
        userGuid: userGuid,
        userId: userId,
        firstName: 'string',
        lastName: 'string',
        phone: 0,
        birthDay: '2024-11-15T14:40:24.368Z',
        email: 'string',
        card: 'string',
        limit: 0,
      };
      const allProps = await api.getTransactionItemsNames();

      await this.setState({
        isLoading: false,
        transactionsProps: allProps.map(({ Name }) => Name),
      });
    } catch (e) {
      const { showError } = this.props;
      showError(e);
      this.setState({ isLoading: false });
    }
  };

  componentDidMount() {
    this.fetchAllTransactionsProps();
  }

  render() {
    const { translate, openModal, closeModal, user } = this.props;
    const { isLoading } = this.state;

    return (
      <Modal
        visible={openModal}
        title={translate('page.clientCabinet.changeClientData')}
        onCancel={closeModal}
        className="AdditionalTransactionsPropsModal"
        footer={[
          // <Button
          //   onClick={() => {
          //     closeModal();
          //   }}
          //   type="primary"
          // >
          //   {translate('core.buttonTitles.select')}
          // </Button>,
          <Button onClick={closeModal}>
            {translate('page.screenControlBusinessServices.modal-set-btn-back-btn')}
          </Button>,
        ]}
      >
        <div className="ChangeClientDataModal-input-wrapper">
          <div className="ChangeClientDataModal-input-col">
            <label>{translate('page.serviceSupplier.fullName')}</label>
            <Input className="ChangeClientDataModal-input" value={user.Name} disabled />
          </div>
          <div className="ChangeClientDataModal-input-col">
            <label>{translate('page.serviceSupplier.phone')}</label>
            <Input className="ChangeClientDataModal-input" value={user.Phone} disabled />
          </div>
          <div className="ChangeClientDataModal-input-col">
            <label>{translate('page.terminalSettings.field-status')}</label>
            <Input className="ChangeClientDataModal-input" value={user.Status} disabled />
          </div>
          <div className="ChangeClientDataModal-input-col">
            <label>{translate('page.serviceSupplier.col-email')}</label>
            <Input className="ChangeClientDataModal-input" value={user.Email} disabled />
          </div>
          <div className="ChangeClientDataModal-input-col">
            <label>{translate('page.beneficiaries.inn')}</label>
            <Input className="ChangeClientDataModal-input" value={user.TaxNumber} disabled />
          </div>
          <div className="ChangeClientDataModal-input-col">
            <label>{translate('page.releases.col-source')}</label>
            <Input className="ChangeClientDataModal-input" value={user.VerificationSource} disabled />
          </div>
          <div className="ChangeClientDataModal-input-row">
            <div className="ChangeClientDataModal-input-col">
              <label>{translate('page.clientCabinet.VerificationDate')}</label>
              <DatePicker
                format="yyyy.MM.dd"
                value={new Date(user.VerificationDate)}
                disabled
                // onChange={(e) => {
                //   this.setState({ dateStart: e.target.value });
                // }}

                // min={BlockedTransactionsPageService.getMinimumSelectedDate()}
                // max={BlockedTransactionsPageService.getMaxSelectedDate()}
              />
            </div>
            <div className="ChangeClientDataModal-input-col">
              <label>{translate('page.clientCabinet.VerificationExpirationDate')}</label>
              <DatePicker
                format="yyyy.MM.dd"
                value={new Date(user.VerificationExpirationDate)}
                disabled
                // onChange={(e) => {
                //   this.setState({ dateStart: e.target.value });
                // }}

                // min={BlockedTransactionsPageService.getMinimumSelectedDate()}
                // max={BlockedTransactionsPageService.getMaxSelectedDate()}
              />
            </div>
          </div>

          <div className="ChangeClientDataModal-input-col">
            <label>{translate('page.blockedTransactions.col-birthday')}</label>
            <DatePicker
              format="yyyy.MM.dd"
              value={new Date(user.Birthday)}
              disabled
              // onChange={(e) => {
              //   this.setState({ dateStart: e.target.value });
              // }}

              // min={BlockedTransactionsPageService.getMinimumSelectedDate()}
              // max={BlockedTransactionsPageService.getMaxSelectedDate()}
            />
          </div>
        </div>

        {isLoading && <LoadingPanel />}
      </Modal>
    );
  }
}

export default withTranslate(ChangeClientDataModal);
