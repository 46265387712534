import React, { PureComponent } from 'react';
import { GridColumn } from '@progress/kendo-react-grid';
import { Col, Row, Checkbox } from 'antd';
import { Button } from '@progress/kendo-react-buttons';

import Grid from '../../../components/grids/baseGrid';
import { ApiService } from '../../../services';
import { withTranslate } from '../../../contexts/localContext';
import checkElement from '../../../utils/checkElement';
import findArray from '../../../utils/findArrForCurrentPage';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import { StandardizeTimeForDate } from '../../../utils/standardizeTimeForDate';

import './ReportByPaymentTypesGrid.styled.scss';
import { getDataByTimezoneOffset } from '../../../utils/getDataByTimezoneOffset';

const api = new ApiService();

const aggregate = [
  { field: 'PaymentTypeCode', aggregate: 'count', format: 'n0' },
  { field: 'PaymentCount', aggregate: 'sum', format: 'n0' },
  { field: 'PaymentAmount', aggregate: 'sum', format: 'n2' },
  { field: 'CommissionProvider', aggregate: 'sum', format: 'n2' },
  { field: 'CommissionClient', aggregate: 'sum', format: 'n2' },
];

class GridReportServices extends PureComponent {
  constructor(props) {
    super(props);
    this.currentArray = findArray('reportByPaymentTypes');
    this.maxDate = StandardizeTimeForDate(new Date());
    this.minDate = new Date(2007, 0, 1, 0, 0, 0);
  }

  state = {
    data: [],
    isLoading: false,
    dateStart: StandardizeTimeForDate(new Date(), true),
    dateEnd: StandardizeTimeForDate(new Date()),
    isSearchByDays: false,
    errorMessage: '',
  };

  makeQuery = async () => {
    const { errorMessage, isSearchByDays, dateStart, dateEnd } = this.state;
    if (!!errorMessage) return;

    try {
      this.setState({ isLoading: true });
      const model = {
        dateStart: getDataByTimezoneOffset(new Date(dateStart)).toISOString(),
        dateEnd: getDataByTimezoneOffset(new Date(dateEnd)).toISOString(),
      };

      const method = isSearchByDays ? 'geServicesPaymentTypeByDays' : 'getServicesPaymentType';

      const data = await api[method](model);
      this.setState({ data, isLoading: false });
    } catch (error) {
      const { showError } = this.props;
      showError(error);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  getSubmitErrorsByDate = (startDate, endDate) => {
    const { translate } = this.props;

    if (!startDate || !endDate) {
      return translate('errorMessages.invalidDateFormat');
    }

    if (StandardizeTimeForDate(new Date(startDate), true) - StandardizeTimeForDate(new Date(endDate)) >= 0) {
      return translate('page.cashDept.differenceDateError');
    }

    if (
      this.minDate - startDate > 0 ||
      this.minDate - endDate > 0 ||
      this.maxDate - startDate < 0 ||
      this.maxDate - endDate < 0
    ) {
      return translate('errorMessages.incorrectlySelectedPeriod');
    }

    return '';
  };

  getDateChangeCallback = (fieldName) => (e) => {
    this.setState(
      {
        [fieldName]: e.target.value,
        errorMessage: '',
      },
      () => {
        this.setState(({ dateStart, dateEnd }) => ({
          errorMessage: this.getSubmitErrorsByDate(dateStart, dateEnd),
        }));
      },
    );
  };

  buildToolbar = () => {
    const { translate } = this.props;
    const { isSearchByDays, dateStart, dateEnd, errorMessage } = this.state;

    return (
      <Col xs={{ order: 2, span: 24 }} lg={{ order: 1, span: 13 }}>
        <Row type="flex" justify="space-between" align="middle">
          <Col xs={24} lg={20}>
            <div className="ReportByPaymentTypesGrid-dateRow">
              <div className="ReportByPaymentTypesGrid-dateRowItem">
                <div className="ReportByPaymentTypesGrid-label">
                  {translate('page.changingProfileHistory.dateFrom')}
                </div>

                <DatePicker
                  format="yyyy.MM.dd"
                  className="FishkaServiceHistoryGrid-datePicker"
                  onChange={this.getDateChangeCallback('dateStart')}
                  value={dateStart}
                  min={this.minDate}
                  max={this.maxDate}
                />
              </div>

              <div className="ReportByPaymentTypesGrid-dateRowItem">
                <div className="ReportByPaymentTypesGrid-label">{translate('page.changingProfileHistory.dateTo')}</div>

                <DatePicker
                  format="yyyy.MM.dd"
                  className="FishkaServiceHistoryGrid-datePicker"
                  onChange={this.getDateChangeCallback('dateEnd')}
                  value={dateEnd}
                  min={this.minDate}
                  max={this.maxDate}
                />
              </div>
            </div>

            <div style={{ marginTop: '8px' }}>
              <Checkbox
                onChange={() => this.setState(({ isSearchByDays }) => ({ isSearchByDays: !isSearchByDays, data: [] }))}
                checked={isSearchByDays}
              >
                {translate('page.reportByPaymentTypes.paymentByDays')}
              </Checkbox>
            </div>
          </Col>

          <Col className="md-mt-10" md={24} lg={4}>
            <Row className="md-daterange--row" type="flex" justify="center">
              <Button className="sm-w-100" icon="search" primary onClick={this.makeQuery} disabled={!!errorMessage}>
                {translate('owner.search')}
              </Button>
            </Row>
          </Col>

          {errorMessage && (
            <Col sm={24} xs={24} className="ReportByPaymentTypesGrid-error">
              {errorMessage}
            </Col>
          )}
        </Row>
      </Col>
    );
  };

  render = () => {
    const { isLoading, data, isSearchByDays } = this.state;

    if (!checkElement('reportByPaymentTypes-content', this.currentArray)) {
      return <></>;
    }

    return (
      <Grid
        data={data}
        aggregate={aggregate}
        onRefresh={this.makeQuery}
        toolbar={this.buildToolbar()}
        isLoading={isLoading}
        name="reportByPaymentTypesGrid"
        key={isSearchByDays}
      >
        <GridColumn
          field="PaymentTypeCode"
          title="page.reportByPaymentTypes.col-paymentTypeNumber"
          width="140"
          filter="numeric"
          format="{0:0}"
        />

        <GridColumn field="PaymentTypeName" title="page.reportByPaymentTypes.col-name" width="225" />

        <GridColumn field="SystemName" title="page.reportByPaymentTypes.col-systemCode" filter="numeric" width="200" />

        <GridColumn
          field="PaymentCount"
          title="page.reportByPaymentTypes.col-paymentCount"
          width="185"
          filter="numeric"
        />

        <GridColumn
          field="PaymentAmount"
          title="page.reportByPaymentTypes.col-paymentAmount"
          width="185"
          filter="numeric"
          format="{0:n2}"
        />

        <GridColumn
          field="CommissionProvider"
          title="page.reportByPaymentTypes.col-commissionProvider"
          width="165"
          filter="numeric"
          format="{0:n2}"
        />

        <GridColumn
          field="CommissionClient"
          title="page.reportByPaymentTypes.col-commissionClient"
          width="165"
          filter="numeric"
          format="{0:n2}"
        />

        {isSearchByDays && (
          <GridColumn
            field="WorkDate"
            title="page.reportByPaymentTypes.col-date"
            width="140"
            filter="date"
            format="dd.MM.yyyy HH:mm:ss"
          />
        )}
      </Grid>
    );
  };
}

export default withTranslate(GridReportServices);
