import React, { PureComponent } from 'react';
import { Button, Row } from 'antd';
import { DatePicker } from '@progress/kendo-react-dateinputs';

import { StandardizeTimeForDate } from '../../utils/standardizeTimeForDate';
import DropdownNormalizersService from '../../core/normalizers/dropdownNormalizers.service';
import { ApiService } from '../../services';
import FiscalizedTransactionsDaysReportService from '../../services/fiscalizedTransactionsDaysReport.service';
import { withTranslate } from '../../contexts/localContext';
import GridDropdown from '../../components/GridDropdown/GridDropdown';
import { OWNERS_COLUMN_CONFIG } from '../../core/constants/configs.const';
import Grid from '../../components/grids/baseGrid';
import { GridColumn } from '@progress/kendo-react-grid';
import findArray from '../../utils/findArrForCurrentPage';
import checkElement from '../../utils/checkElement';
import { FISCALIZED_TRANSACTIONS_DAYS_REPORT_AGGREGATE } from '../../core/constants/aggregates';

import './FiscalizedTransactionsOwnersDaysReportGrid.styled.scss';
import { getDataByTimezoneOffset } from '../../utils/getDataByTimezoneOffset';

const api = new ApiService();

const aggregate = [
  { field: 'OwnerId', aggregate: 'count', format: 'n0' },
  ...FISCALIZED_TRANSACTIONS_DAYS_REPORT_AGGREGATE,
];

class FiscalizedTransactionsOwnersDaysReportGrid extends PureComponent {
  currentArray = findArray('fiscalizedTransactionsOwnersDaysReport');

  state = {
    isLoading: false,
    owners: [],
    selectedOwners: [],
    dateStart: StandardizeTimeForDate(new Date(), true),
    dateEnd: StandardizeTimeForDate(new Date()),
    error: '',
    transactions: [],
  };

  componentDidMount() {
    this.fetchOwners();
  }

  fetchTransactions = async () => {
    const { error, selectedOwners, dateStart, dateEnd } = this.state;

    if (!!error || !selectedOwners.length) {
      return;
    }

    try {
      this.setState({ isLoading: true });

      const transactions = await api.fiscalizedTransactionsByOwners({
        owners: selectedOwners,
        dateStart: getDataByTimezoneOffset(dateStart).toISOString(),
        dateEnd: getDataByTimezoneOffset(dateEnd).toISOString(),
      });

      this.setState({
        isLoading: false,
        transactions,
      });
    } catch (error) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(error);
    }
  };

  fetchOwners = async () => {
    try {
      this.setState({ isLoading: true });
      const owners = await api.owners();

      this.setState({
        owners: DropdownNormalizersService.normalizeOwners(owners),
        isLoading: false,
      });
    } catch (error) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(error);
    }
  };

  getDateChangeCallback = (fieldName) => (e) => {
    const { translate } = this.props;

    this.setState(
      {
        [fieldName]: e.target.value,
        error: '',
      },
      () => {
        this.setState(({ dateStart, dateEnd }) => ({
          error: FiscalizedTransactionsDaysReportService.getSubmitErrorsByDate(dateStart, dateEnd, translate),
        }));
      },
    );
  };

  buildToolbar = () => {
    const { translate } = this.props;
    const { selectedOwners, owners, dateStart, dateEnd, error } = this.state;

    return (
      <Row className="FiscalizedTransactionsOwnersDaysReportGrid-toolbar">
        <div>
          <div className="FiscalizedTransactionsOwnersDaysReportGrid-fieldRow">
            <div className="FiscalizedTransactionsOwnersDaysReportGrid-label">
              {translate('page.commissionsServices.dealer')}
            </div>

            <div className="FiscalizedTransactionsOwnersDaysReportGrid-select">
              <GridDropdown
                data={owners}
                colConfig={OWNERS_COLUMN_CONFIG}
                selectItems={selectedOwners}
                onSave={(selectedOwners) => {
                  this.setState({ selectedOwners });
                }}
                defaultTitle={translate('page.dealerCommissions.selectDealer')}
                isGetDefaultSelectItemsFromStorage
              />
            </div>
          </div>

          <div className="FiscalizedTransactionsOwnersDaysReportGrid-dataFields">
            <div className="FiscalizedTransactionsOwnersDaysReportGrid-fieldRow">
              <div className="FiscalizedTransactionsOwnersDaysReportGrid-label">
                {translate('page.changingProfileHistory.dateFrom')}
              </div>
              <DatePicker
                format="yyyy.MM.dd"
                onChange={this.getDateChangeCallback('dateStart')}
                value={dateStart}
                min={FiscalizedTransactionsDaysReportService.getMinimumSelectedDate()}
              />
            </div>

            <div className="FiscalizedTransactionsOwnersDaysReportGrid-fieldRow">
              <div className="FiscalizedTransactionsOwnersDaysReportGrid-label">
                {translate('page.changingProfileHistory.dateTo')}
              </div>
              <DatePicker
                format="yyyy.MM.dd"
                onChange={this.getDateChangeCallback('dateEnd')}
                value={dateEnd}
                min={FiscalizedTransactionsDaysReportService.getMinimumSelectedDate()}
              />
            </div>
          </div>

          {error && <div className="FiscalizedTransactionsOwnersDaysReportGrid-error">{error}</div>}
        </div>

        <div className="FiscalizedTransactionsOwnersDaysReportGrid-searchAction">
          <Button type="primary" disabled={!!error || !selectedOwners.length} onClick={this.fetchTransactions}>
            {translate('core.buttonTitles.search')}
          </Button>
        </div>
      </Row>
    );
  };

  render() {
    const { transactions, isLoading } = this.state;

    if (!checkElement('fiscalizedTransactionsOwnersDaysReport-content', this.currentArray)) {
      return null;
    }

    return (
      <Grid
        data={transactions}
        name="FiscalizedTransactionsOwnersDaysReportGrid"
        toolbar={this.buildToolbar()}
        onRefresh={this.fetchTransactions}
        isLoading={isLoading}
        aggregate={aggregate}
      >
        <GridColumn
          field="OwnerId"
          title="page.fiscalizedTransactionsOwnersDaysReport.col-ownerId"
          width="110"
          filter="numeric"
        />

        <GridColumn field="OwnerName" title="page.fiscalizedTransactionsOwnersDaysReport.col-owner" width="240" />

        <GridColumn field="TransactionWorkDate" title="page.fiscalizedTransactionsDaysReport.col-date" width="220" />

        <GridColumn
          field="CashCount"
          title="page.fiscalizedTransactionsDaysReport.col-totalSum"
          width="220"
          filter="numeric"
        />

        <GridColumn
          field="CashAmount"
          title="page.fiscalizedTransactionsDaysReport.col-revenueSum"
          width="180"
          filter="numeric"
        />

        <GridColumn
          field="CashCommission"
          title="page.fiscalizedTransactionsDaysReport.col-commissionSum"
          width="180"
          filter="numeric"
        />

        <GridColumn
          field="CashlessCount"
          title="page.fiscalizedTransactionsDaysReport.col-totalSumPK"
          width="200"
          filter="numeric"
        />

        <GridColumn
          field="CashlessAmount"
          title="page.fiscalizedTransactionsDaysReport.col-revenueSumPK"
          width="160"
          filter="numeric"
        />

        <GridColumn
          field="CashlessCommission"
          title="page.fiscalizedTransactionsDaysReport.col-commissionSumPK"
          width="160"
          filter="numeric"
        />
      </Grid>
    );
  }
}

export default withTranslate(FiscalizedTransactionsOwnersDaysReportGrid);
