import React, { PureComponent } from 'react';
import { GridColumn } from '@progress/kendo-react-grid';

import Grid from '../../components/grids/baseGrid';
import { withTranslate } from '../../contexts/localContext';
import { ApiService } from '../../services';
import { getDataByTimezoneOffset } from '../../utils/getDataByTimezoneOffset';

const api = new ApiService();

class PayerPhoneAndCardNumberHistoryGrid extends PureComponent {
  state = {
    services: [],
    isLoading: false,
  };

  componentDidMount() {
    this.fetchServices();
  }

  fetchServices = async () => {
    try {
      await this.setState({ isLoading: true });
      const services = await api.getGatewayRuleExceptionHistory();
      this.setState({
        isLoading: false,
        services: services.map(({ DatePostFrom, DatePostTo, ...restData }) => ({
          ...restData,
          DatePostFrom: getDataByTimezoneOffset(new Date(DatePostFrom)).toISOString(),
          DatePostTo: getDataByTimezoneOffset(new Date(DatePostTo)).toISOString(),
        })),
      });
    } catch (error) {
      const { showError } = this.props;
      showError(error);
      await this.setState({ isLoading: false });
    }
  };

  render() {
    const { services, isLoading } = this.state;

    return (
      <Grid
        data={services}
        name="payerPhoneAndCardNumberHistoryGrid"
        toolbar={<></>}
        onRefresh={this.fetchServices}
        isLoading={isLoading}
      >
        <GridColumn field="ServiceId" title="grids.servicesBigTable.column-serviceId" width="150px" filter="numeric" />
        <GridColumn field="ServiceName" title="page.commissionsServices.col-serviceName" width="480" />
        <GridColumn field="F108" title="page.OTPServices.col-NBU" width="150" />
        <GridColumn
          field="DatePostTo"
          title="page.OTPServices.col-date"
          width="160"
          filter="date"
          format="dd.MM.yyyy HH:mm:ss"
        />
        <GridColumn field="UserName" title="page.OTPServices.col-performer" width="240" />
        <GridColumn
          field="DatePostFrom"
          title="page.payerPhoneAndCardNumber.startDate"
          width="150px"
          filter="date"
          format="dd.MM.yyyy HH:mm:ss"
        />
        <GridColumn
          field="DatePostTo"
          title="page.payerPhoneAndCardNumber.endDate"
          width="150px"
          filter="date"
          format="dd.MM.yyyy HH:mm:ss"
        />
      </Grid>
    );
  }
}

export default withTranslate(PayerPhoneAndCardNumberHistoryGrid);
