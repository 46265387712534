const payerPhoneAndCardNumber = (translate, value) => {
  switch (value) {
    case 'checkPayerPhoneAndCardNumber-content':
      return `${translate(`page.adminPanel.pageContent`)}`;
    case 'checkPayerPhoneAndCardNumber-add':
      return `${translate(`page.adminPanel.type-button`)}:
                        ${translate('core.buttonTitles.add')}`;
    case 'checkPayerPhoneAndCardNumber-delete':
      return `${translate(`page.adminPanel.type-button`)}:
                        ${translate('core.buttonTitles.delete')}`;
    case 'checkPayerPhoneAndCardNumber-history':
      return `${translate(`page.adminPanel.type-navLink`)}:
                        ${translate('page.payerPhoneAndCardNumber.changesHistory')}`;
    default:
      return value;
  }
};

export default payerPhoneAndCardNumber;
