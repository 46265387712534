export const HANDLERS = [
  {
    title: 'page.dashboard.tab-userProperties',
    type: 'userProperties',
    webTag: 'users-tabUserProperties',
  },
  {
    title: 'page.dashboard.tab-group-membership',
    type: 'membership',
    webTag: 'users-tabGroupMembership',
  },
  {
    title: 'page.dashboard.tab-workDealers',
    type: 'workDealers',
    webTag: 'users-tabWorkDealers',
  },
  {
    title: 'page.dashboard.tab-access-terminals',
    type: 'accessTerminals',
    webTag: 'users-tabTerminalAcess',
  },
  {
    title: 'page.userServicesAccess.tabName',
    type: 'userServicesAccess',
    webTag: 'users-servicesAccess',
  },
];
