import React, { PureComponent } from 'react';
import { GridColumn } from '@progress/kendo-react-grid';
import { Button, Row, Select } from 'antd';

import Grid from '../../components/grids/baseGrid';
import { withTranslate } from '../../contexts/localContext';

import './UnsuccessfulPaymentsAnalysisGrid.styled.scss';
import UnsuccessfulPaymentsAnalysisService from './UnsuccessfulPaymentsAnalysis.service';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import DropdownNormalizersService from '../../core/normalizers/dropdownNormalizers.service';
import { ApiService } from '../../services';
import { PAYMENT_TYPES_COLUMN_CONFIG } from '../../core/constants/configs.const';
import GridDropdown from '../../components/GridDropdown/GridDropdown';
import { getDataByTimezoneOffset } from '../../utils/getDataByTimezoneOffset';
import { TRANSACTION_STATUSES } from '../../core/constants/transactionStatuses';
import findArray from '../../utils/findArrForCurrentPage';
import checkElement from '../../utils/checkElement';

const { Option } = Select;
const api = new ApiService();

class UnsuccessfulPaymentsAnalysisGrid extends PureComponent {
  currentArray = findArray('unsuccessfulPaymentsAnalysis');

  state = {
    transactions: [],
    isLoading: false,
    dateStart: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 2, 0, 0, 0),
    dateEnd: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 2, 23, 59, 59, 999),
    errorDateMessage: '',
    paymentTypes: [],
    terminalTypes: [],
    selectedPaymentTypes: [],
    selectTerminalTypes: undefined,
    selectedStatuses: undefined,
  };

  aggregate = [
    { field: 'TransactionId', aggregate: 'count', format: 'n0' },
    { field: 'AmountTotal', aggregate: 'sum', format: 'n2' },
    { field: 'AmountOriginal', aggregate: 'sum', format: 'n2' },
  ];

  componentDidMount() {
    this.initial();
  }

  getChangeDateCallback =
    (fieldName) =>
    ({ target: { value } }) => {
      const { translate } = this.props;

      this.setState(
        {
          [fieldName]: value,
          errorDateMessage: '',
        },
        () => {
          this.setState(({ dateStart, dateEnd }) => ({
            errorDateMessage: UnsuccessfulPaymentsAnalysisService.getValidateDate(dateStart, dateEnd, translate),
          }));
        },
      );
    };

  initial = async () => {
    await this.setState({ isLoading: true });
    await this.getPaymentType();
    await this.fetchTerminalTypes();
    await this.setState({ isLoading: false });
  };

  getPaymentType = async () => {
    try {
      const data = await api.getPaymentTypeList();

      this.setState({
        paymentTypes: data && data.length ? DropdownNormalizersService.normalizePaymentTypes(data) : [],
      });
    } catch (error) {
      const { showError } = this.props;
      this.setState({ isLoading: false });
      showError(error);
    } finally {
    }
  };

  fetchTerminalTypes = async () => {
    const { translate } = this.props;

    try {
      const terminalTypes = await api.getTerminalsTypes();

      this.setState({
        terminalTypes: [
          { TypeName: translate('page.unsuccessfulPaymentsAnalysis.allTypes'), TypeId: 0 },
          ...terminalTypes,
        ],
      });
    } catch (e) {
      const { showError } = this.props;
      showError(e);
      this.setState({ isLoading: false });
    }
  };

  getIsSearchNotAllowed = () => {
    const { selectedPaymentTypes, selectTerminalTypes, selectedStatuses, errorDateMessage } = this.state;

    return (
      !!errorDateMessage ||
      !selectTerminalTypes ||
      !selectTerminalTypes.length ||
      !selectedStatuses ||
      !selectedStatuses.length ||
      !selectedPaymentTypes.length
    );
  };

  buildToolbar = () => {
    const { translate } = this.props;
    const {
      dateStart,
      dateEnd,
      paymentTypes,
      selectedPaymentTypes,
      selectTerminalTypes,
      selectedStatuses,
      terminalTypes,
      errorDateMessage,
    } = this.state;

    return (
      <Row className="UnsuccessfulPaymentsAnalysisGrid-toolbar">
        <div className="UnsuccessfulPaymentsAnalysisGrid-toolbarContent">
          <div className="UnsuccessfulPaymentsAnalysisGrid-toolbarContentItem">
            <div className="UnsuccessfulPaymentsAnalysisGrid-dateRow">
              <div className="UnsuccessfulPaymentsAnalysisGrid-fieldRow">
                <div className="UnsuccessfulPaymentsAnalysisGrid-dateLabel">
                  {translate('page.changingProfileHistory.dateFrom')}
                </div>

                <DatePicker
                  format="yyyy.MM.dd"
                  onChange={this.getChangeDateCallback('dateStart')}
                  value={dateStart}
                  min={UnsuccessfulPaymentsAnalysisService.getMinimumSelectedDate()}
                  max={UnsuccessfulPaymentsAnalysisService.getMaxSelectedDate()}
                />
              </div>
              <div className="UnsuccessfulPaymentsAnalysisGrid-fieldRow">
                <div className="UnsuccessfulPaymentsAnalysisGrid-dateLabel">
                  {translate('page.changingProfileHistory.dateTo')}
                </div>

                <DatePicker
                  format="yyyy.MM.dd"
                  onChange={this.getChangeDateCallback('dateEnd')}
                  value={dateEnd}
                  min={UnsuccessfulPaymentsAnalysisService.getMinimumSelectedDate()}
                  max={UnsuccessfulPaymentsAnalysisService.getMaxSelectedDate()}
                />
              </div>
            </div>
          </div>

          <div className="UnsuccessfulPaymentsAnalysisGrid-toolbarContentItem">
            <div className="UnsuccessfulPaymentsAnalysisGrid-selectRow">
              <div className="UnsuccessfulPaymentsAnalysisGrid-dateLabel UnsuccessfulPaymentsAnalysisGrid--increasedDataLabelWith">
                {translate('page.unsuccessfulPaymentsAnalysis.status')}
              </div>

              <Select
                value={selectedStatuses}
                onChange={(selectedStatuses) => {
                  this.setState({ selectedStatuses });
                }}
                className="UnsuccessfulPaymentsAnalysisGrid-select"
                placeholder={translate('page.unsuccessfulPaymentsAnalysis.selectStatus')}
                mode="multiple"
              >
                {TRANSACTION_STATUSES.map(({ value, title }) => (
                  <Option value={value}>{title}</Option>
                ))}
              </Select>
            </div>
          </div>

          <div className="UnsuccessfulPaymentsAnalysisGrid-toolbarContentItem">
            <div className="UnsuccessfulPaymentsAnalysisGrid-selectRow">
              <div className="UnsuccessfulPaymentsAnalysisGrid-selectLabel">
                {translate('grids.transactions.column-typePayment')}
              </div>

              <GridDropdown
                className="UnsuccessfulPaymentsAnalysisGrid-select"
                data={paymentTypes}
                colConfig={PAYMENT_TYPES_COLUMN_CONFIG}
                selectItems={selectedPaymentTypes}
                onSave={(selectedPaymentTypes) => {
                  this.setState({ selectedPaymentTypes });
                }}
                defaultTitle={translate('page.commissionsServices.choosePaymentType')}
                isAllowEmptyList
              />
            </div>
          </div>

          <div className="UnsuccessfulPaymentsAnalysisGrid-toolbarContentItem">
            <div className="UnsuccessfulPaymentsAnalysisGrid-selectRow">
              <div className="UnsuccessfulPaymentsAnalysisGrid-longSelectLabel">
                {translate('page.transactions.terminalType')}
              </div>

              <Select
                value={selectTerminalTypes}
                onChange={(selectTerminalTypes) => {
                  this.setState({ selectTerminalTypes });
                }}
                className="UnsuccessfulPaymentsAnalysisGrid-select"
                placeholder={translate('page.transactions.selectTerminalType')}
                mode="multiple"
              >
                {terminalTypes.map(({ TypeId, TypeName }) => (
                  <Option key={TypeId} value={TypeId}>
                    {TypeName}
                  </Option>
                ))}
              </Select>
            </div>
          </div>
        </div>

        <div className="UnsuccessfulPaymentsAnalysisGrid-toolbarAction">
          <Button type="primary" onClick={this.onSearch} disabled={this.getIsSearchNotAllowed()}>
            {translate('core.buttonTitles.search')}
          </Button>
        </div>

        {errorDateMessage && <div className="UnsuccessfulPaymentsAnalysisGrid-error">{errorDateMessage}</div>}
      </Row>
    );
  };

  onSearch = async () => {
    const { dateStart, dateEnd, selectTerminalTypes, selectedStatuses, selectedPaymentTypes } = this.state;

    await this.setState({ isLoading: true });

    if (this.getIsSearchNotAllowed()) {
      return;
    }

    try {
      const transactions = await api.findDeclinedTransaction({
        owners: [0],
        statusId: selectedStatuses || [],
        terminalTypes: selectTerminalTypes || [],
        paymentTypes: selectedPaymentTypes,
        chequeNumber: '',
        detailName: '',
        detailValue: '',
        dateStart: getDataByTimezoneOffset(UnsuccessfulPaymentsAnalysisService.getStartValue(dateStart)).toISOString(),
        dateEnd: getDataByTimezoneOffset(UnsuccessfulPaymentsAnalysisService.getEndValue(dateEnd)).toISOString(),
        displayComments: true,
        displayUser: true,
        hist: true,
      });

      this.setState({ transactions, isLoading: false });
    } catch (e) {
      const { showError } = this.props;
      showError(e);
      this.setState({ isLoading: false });
    }
  };

  render() {
    const { transactions, isLoading } = this.state;

    if (!checkElement('unsuccessfulPaymentsAnalysis-content', this.currentArray)) {
      return null;
    }

    return (
      <Grid
        data={transactions}
        toolbar={this.buildToolbar()}
        onRefresh={this.onSearch}
        name="unsuccessfulPaymentsAnalysisGrid"
        isLoading={isLoading}
        aggregate={this.aggregate}
      >
        <GridColumn field="OwnerName" width="145" title="grids.transactions.column-ownerName" />
        <GridColumn
          field="TransactionId"
          title="grids.transactions.column-transactionId"
          width="130"
          filter="numeric"
        />
        <GridColumn field="RequestId" title="grids.transactions.column-requestId" width="112" filter="numeric" />
        <GridColumn field="TerminalId" title="grids.transactions.column-terminalId" width="124" filter="numeric" />
        <GridColumn field="ServiceId" title="grids.transactions.column-serviceId" width="110" filter="numeric" />
        <GridColumn field="ServiceName" title="grids.transactions.column-serviceName" width="93" />
        <GridColumn
          field="AmountTotal"
          title="grids.transactions.column-amountTotal"
          width="134"
          filter="numeric"
          format="{0:n2}"
        />
        <GridColumn
          field="AmountOriginal"
          title="grids.transactions.column-amountOriginal"
          width="180"
          filter="numeric"
          format="{0:n2}"
        />
        <GridColumn
          field="DateInput"
          title="grids.transactions.column-dateInput"
          width="142"
          filter="date"
          format="dd.MM.yyyy HH:mm:ss"
        />
        <GridColumn field="Account" title="grids.transactions.column-account" width="112" />
        <GridColumn field="Comment" title="grids.transactions.column-comment" width="125" />
        <GridColumn field="StatusName" title="grids.transactions.column-status" width="91" />
        <GridColumn field="PaymentType" title="grids.transactions.column-typePayment" width="125" />
        <GridColumn field="OperationName" title="page.transactions.operation" width="125" />
        <GridColumn field="TerminalType" title="page.transactions.terminalType" width="125" />
      </Grid>
    );
  }
}

export default withTranslate(UnsuccessfulPaymentsAnalysisGrid);
