export const USER_COL_CONFIG = [
  {
    fieldName: 'Id',
    title: '#',
    width: '80',
    filter: 'numeric',
    isShowOnMobile: true,
  },
  {
    fieldName: 'Name',
    titleTranslateSlug: 'grids.userList.column-login',
    width: '120',
    isShowOnMobile: true,
  },
  {
    fieldName: 'FirstName',
    titleTranslateSlug: 'grids.userList.column-firstName',
    width: '160',
  },
  {
    fieldName: 'LastName',
    titleTranslateSlug: 'grids.userList.column-lastName',
    width: '240',
    filter: 'numeric',
  },
];
