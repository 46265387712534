import React, { PureComponent } from 'react';
import { Modal, Button, message } from 'antd';

import { withTranslate } from '../../../contexts/localContext';
import { ApiService } from '../../../services';
import LoadingPanel from '../../../components/loader';

const api = new ApiService();

class SetUserServicesAccessModal extends PureComponent {
  state = {
    isLoading: false,
  };

  updateServicesAccess = async () => {
    const { selectedItems, dialogType, userId, onRefresh, translate, closeModal } = this.props;

    try {
      await this.setState({ isLoading: true });
      await api[dialogType === 'cancelAccess' ? 'deleteServicesAccess' : 'mergeServicesAccess']({
        serviceIds: selectedItems.map(({ ServiceId }) => +ServiceId),
        userId: userId,
      });

      this.setState({ isLoading: false });
      closeModal();
      onRefresh();
      message.success(
        translate(
          dialogType === 'cancelAccess'
            ? 'page.userServicesAccess.successfullyCanceled'
            : 'page.userServicesAccess.successfullyGranted',
        ),
        2,
      );
    } catch (e) {
      this.setState({ isLoading: false });
      const { showError } = this.props;
      showError(e);
    }
  };

  render() {
    const { translate, dialogType, closeModal } = this.props;
    const { isLoading } = this.state;

    return (
      <Modal
        title={translate(
          dialogType === 'cancelAccess'
            ? 'page.userServicesAccess.cancelAccess'
            : 'page.userServicesAccess.grantAccess',
        )}
        visible={!!dialogType}
        onCancel={closeModal}
        footer={[
          <Button key="submit" type="primary" onClick={this.updateServicesAccess}>
            {translate(
              dialogType === 'cancelAccess' ? 'page.userServicesAccess.cancel' : 'page.userServicesAccess.grant',
            )}
          </Button>,
          <Button key="back" onClick={closeModal}>
            {translate('core.buttonTitles.refuse')}
          </Button>,
        ]}
      >
        {translate(
          dialogType === 'cancelAccess'
            ? 'page.userServicesAccess.areYouSureToCancel'
            : 'page.userServicesAccess.areYouSureToGrant',
        )}
        {isLoading && <LoadingPanel />}
      </Modal>
    );
  }
}

export default withTranslate(SetUserServicesAccessModal);
