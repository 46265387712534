import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Dashboard from './dashboard';
import MonitorActivity from './monitorActivity/monitorActivity';
import Transactions from './transactions';
import Contacts from './contacts';
import NotFound from './notFound';
import ReportServices from './reports/ReportByServices';
import ReportDay from './reports/ReportsByDay';
import ReportTurnovers from './reports/ReportByTurnovers';
import ReportPeriod from './reports/ReportsByPeriod';
import ReportOperabilityPeriod from './reports/terminalsOperabilityPeriod';
import ReportOperabilityDay from './reports/terminalOperabilityDay';
import Account from './account';
import EncashmentTerminals from './encashmentTerminals';
import EncashmentAll from './encashmentAll';
import Profile from './profile';
import Certificates from './certificates';
import Comissions from './comissions';
import TechInform from './techInform';
import ScreenControlTerminals from './screenControl(terminalsControl)/terminals';
import ScreenControlProfiles from './screenControl(terminalsControl)/profiles';
import ScreenControlBusinessServices from './screenControl(terminalsControl)/businessServices';
import ScreenControlSettingsAndEvents from './screenControl(terminalsControl)/settingsAndEvents';
import DepricatedScreenControlSettingsAndEvents from './screenControl(terminalsControl)/settingsAndEvents/depricatedSettingsAndEvents';
import CreateTerminal from './createTerminal';
import AdminPanel from './admin';
import AdminStockPhotos from './adminStockPhotos';
import AdminGeneralResults from './adminGeneralResults';
import OwnerStockGeneralResults from './ownerStockGeneralResults';
import OwnerStockPhotos from './ownerStockPhotos';
import OwnerStockAbout from './ownerAboutStock';
import WhiteListAdmin from './whiteListAdmin';
import Messages from './messages';
import TerminalsConfig from './screenControl(terminalsControl)/terminalsConfig';
import AddingAddressedPayment from './addingAdressedPayment';
import MonitoringCabinet from './monitoringCabinet';
import TerminalsMaintenance from './TerminalsMaintenance';
import Commission from './commission';
import PageCommissionsProfile from './CommissionProfiles';
import CashDebt from './cashdebt/CashDebtPage';
import ChangingProfileHistory from './ChangingProfileHistory';
import CommissionsServicesPage from './CommissionsServices';
import Settlements from './Settlements';
import CommissionTerminalsPage from './CommissionTerminals';
import DealerCommissionsPage from './DealerCommissions';
import ServicesPage from './Services';
import AllElements from '../pages/AllElementsPage';
import RemainingTerminalsPage from './remainingTerminals/RemainingTerminalsPage';
import CashCollectorsPage from './CashCollectors';
import TerminalsByServicesPage from './terminalsByServices/TerminalsByServicesPage';
import TerminalsByDaysPages from './terminalsByDays/TerminalsByDayPage';
import DispenserCollection from './DispenserCollection';
import UsersPage from './Users/UsersPage';
import { AllElementsPage, ReleasePage } from './Releases';
import BackupButtons from './BackupButtons';
import NonRiskLimits from './NonRiskLimits';
import FishkaService from './FishkaService';
import CashDebtByCollectors from './CashDebtByCollectors';
import ExceptionsFinMonLimitPage from './ServiceManagement/ExceptionsFinMonLimit';
import ServicesBigTablePage from './ServiceManagement/Serivices';
import DealerCommissionsServicesPage from './DealerCommissionsServices';
import DealerCommissionsPaymentToolsPage from './DealerCommissionsPaymentTools';
import SuspendedFinancialTransactions from './SuspendedFinancialTransactions';
import PaymentTypeNBUCode from './PaymentTypeNBUCode';
import ServiceLimits from './ServiceLimits';
import SubBrandsTerminals from './SubBrandsTerminals';
import BlockedTransactions from './BlockedTransactions';
import FinancialTransaction from './FinancialTransaction/FinancialTransaction';
import SuppliersBalance from './SuppliersBalance';
import RecalculationTransactionsPage from './ServiceManagement/RecalculationTransactions';
import ServicesAuthorizationLimits from './ServicesAuthorizationLimits';
import DealerCommissionsTerminals from './DealerCommissionsTerminals';
import ManagementPaymentTypesPage from './ManagmentPaymentTypes/ManagmentPaymentTypesPage';
import DealerCommissionsHistory from './DealerCommissionsHistory';
import AuthorizationsDictionary from './AuthorizationsDictionary';
import OTPServices from './OTPServices';
import CheckTemplate from './CheckTemplate';
import TemplateServices from './TemplateServices';
import TemplateTerminals from './TemplateTerminals';
import DealerCommissionsExpectedChanges from './DealerCommissionsExpectedChanges';
import PaymentTypesDictionary from './PaymentTypesDictionary';
import GroupServices from './ServiceManagement/Serivices/GroupServices';
import CardRouting from './CardRouting';
import ManagementMenu from './ManagementMenu';
import TerminalProfiles from './TerminalProfiles';
import MenuAudit from './menuAudit';
import MenuElementPropDictionary from './MenuElementPropDictionary';
import NavigationMenuProp from './NavigationMenuProp';
import EasyPayUsers from './EasyPayUsers';
import ManagementErrorCodes from './ManagementErrorCodes';
import PartnerInfoSender from './PartnerInfoSender';
import PreviewApplication from './PreviewApplication';
import ManageMerchantCallbacks from './ManageMerchantCallbacks';
import ReportByPaymentTypes from './reports/ReportByPaymentTypes';
import MFO from './MFO';
import TechnicalPartners from './TechnicalPartners';
import ServiceSuppliers from './ServiceSuppliers';
import NavigationMenuElements from './NavigationMenuElements';
import Beneficiaries from './Beneficiaries/Beneficiaries';
import Directors from './Directors/Directors';
import FiscalizedTransactionsDaysReport from './FiscalizedTransactionsDaysReport';
import FiscalizedTransactionsOwnersDaysReport from './FiscalizedTransactionsOwnersDaysReport';
import ExternalOutgoingPaymentsPage from './ExternalOutgoingPayments';
import TransactionsWithCashback from './TransactionsWithCashback';
import TerminalsActiveServices from './TerminalsActiveServices';
import MFOTransactionsPage from './MFOTransactions/MFOTransactionsPage';
import UnsuccessfulPaymentsAnalysis from './UnsuccessfulPaymentsAnalysis/UnsuccessfulPaymentsAnalysis';
import ClientCabinetPage from './ClientCabinet/ClientCabinetPage';
import PayerPhoneAndCardNumber from './PayerPhoneAndCardNumber';
import PageRemoteTerminalSettings from './RemoteTerminalSettings';
import ServiceUserAccess from './ServiceUserAccess';

const PagesRouting = () => (
  <Route
    path="/pages"
    render={({ match: { url } }) => (
      <Switch>
        <Route path={`${url}/`} component={Dashboard} exact />
        <Route path={`${url}/monitorActivity`} component={MonitorActivity} exact />
        <Route path={`${url}/transactions`} component={Transactions} exact />
        <Route path={`${url}/financialTransactions`} component={FinancialTransaction} exact />
        <Route path={`${url}/contacts`} component={Contacts} exact />
        <Route path={`${url}/reports/service`} component={ReportServices} exact />
        <Route path={`${url}/reports/day`} component={ReportDay} exact />
        <Route path={`${url}/reports/turnovers`} component={ReportTurnovers} exact />
        <Route path={`${url}/reports/period`} component={ReportPeriod} exact />
        <Route path={`${url}/reports/terminalMaintenance`} component={TerminalsMaintenance} />
        <Route path={`${url}/reports/cashDebt`} component={CashDebt} />
        <Route path={`${url}/account`} component={Account} exact />
        <Route path={`${url}/encashmentTerminals`} component={EncashmentTerminals} exact />
        <Route path={`${url}/encashmentAll`} component={EncashmentAll} exact />
        <Route path={`${url}/profile`} component={Profile} exact />
        <Route path={`${url}/certificates`} component={Certificates} exact />
        <Route path={`${url}/tech-inform`} component={TechInform} exact />
        <Route path={`${url}/terminal_control/terminals`} component={ScreenControlTerminals} exact />
        <Route path={`${url}/terminal_control/profiles`} component={ScreenControlProfiles} exact />
        <Route path={`${url}/terminal_control/business-services`} component={ScreenControlBusinessServices} exact />
        <Route path={`${url}/terminal_control/createTerminal`} component={CreateTerminal} />
        <Route path={`${url}/terminal_control/settings`} component={DepricatedScreenControlSettingsAndEvents} />
        <Route path={`${url}/terminal_control/newSettings`} component={ScreenControlSettingsAndEvents} />
        <Route path={`${url}/terminal_control/configuration`} component={TerminalsConfig} />
        <Route path={`${url}/terminal_control/previewApplication`} component={PreviewApplication} />
        <Route path={`${url}/admin`} component={AdminPanel} />
        <Route path={`${url}/serviceUserAccess`} component={ServiceUserAccess} />
        <Route path={`${url}/stock/adminStockPhotos`} component={AdminStockPhotos} />
        <Route path={`${url}/stock/adminGeneralResults`} component={AdminGeneralResults} />
        <Route path={`${url}/stock/ownerStockGeneralResults`} component={OwnerStockGeneralResults} />
        <Route path={`${url}/stock/ownerStockPhotos`} component={OwnerStockPhotos} />
        <Route path={`${url}/stock/ownerStockAbout`} component={OwnerStockAbout} />
        <Route path={`${url}/reports/reportOperabilityPeriod`} component={ReportOperabilityPeriod} />
        <Route path={`${url}/reports/reportOperabilityDay`} component={ReportOperabilityDay} />
        <Route path={`${url}/messages`} component={Messages} />
        <Route path={`${url}/adding-address-payment`} component={AddingAddressedPayment} />
        <Route path={`${url}/monitoringCabinet`} component={MonitoringCabinet} />
        <Route path={`${url}/managementCommissions/commission`} component={Commission} exact />
        <Route path={`${url}/managementCommissions/profiles`} component={PageCommissionsProfile} />
        <Route path={`${url}/managementCommissions/changingProfileHistory`} component={ChangingProfileHistory} />
        <Route path={`${url}/managementCommissions/services`} component={CommissionsServicesPage} />
        <Route path={`${url}/managementCommissions/terminals`} component={CommissionTerminalsPage} />
        <Route path={`${url}/dictionary/clientCommissions`} component={Comissions} exact />
        <Route path={`${url}/dictionary/cities`} component={Settlements} />
        <Route path={`${url}/dictionary/dealerCommissions`} component={DealerCommissionsPage} />
        <Route path={`${url}/dictionary/whiteListAdmin`} component={WhiteListAdmin} />
        <Route path={`${url}/dictionary/service`} component={ServicesPage} />
        <Route path={`${url}/dictionary/serviceLimits`} component={ServiceLimits} />
        <Route path={`${url}/dictionaryManagement/authorizations`} component={AuthorizationsDictionary} />
        <Route path={`${url}/dictionaryManagement/paymentTypes`} component={PaymentTypesDictionary} />
        <Route path={`${url}/encashment/remainingTerminals`} component={RemainingTerminalsPage} />
        <Route path={`${url}/encashment/cashCollectors`} component={CashCollectorsPage} />
        {/*<Route path={`${url}/encashment/dispenserCollection`} component={DispenserCollection} />*/}
        <Route path={`${url}/reports/terminalsByServices`} component={TerminalsByServicesPage} />
        <Route path={`${url}/reports/terminalsByDays`} component={TerminalsByDaysPages} />
        <Route path={`${url}/users`} component={UsersPage} />
        <Route path={`${url}/t7jU3J7CYj9vt5T9g4x54MkiF79iVBtA`} component={AllElements} />
        <Route path={`${url}/releases`} component={ReleasePage} />
        <Route path={`${url}/terminal_control/backupButtons`} component={BackupButtons} />
        <Route path={`${url}/nonRiskLimits`} component={NonRiskLimits} />
        <Route path={`${url}/fishkaService`} component={FishkaService} />
        <Route path={`${url}/reports/cashDebtByCollectors`} component={CashDebtByCollectors} f />
        <Route path={`${url}/serviceManagement/exceptionsFinMonLimit`} component={ExceptionsFinMonLimitPage} />
        <Route path={`${url}/serviceManagement/services`} component={ServicesBigTablePage} />
        <Route path={`${url}/serviceManagement/recalculationTransactions`} component={RecalculationTransactionsPage} />
        <Route path={`${url}/serviceManagement/OTPServices`} component={OTPServices} />
        <Route path={`${url}/dealerCommissions/services`} component={DealerCommissionsServicesPage} />
        <Route path={`${url}/dealerCommissions/paymentTools`} component={DealerCommissionsPaymentToolsPage} />
        <Route path={`${url}/dealerCommissions/terminals`} component={DealerCommissionsTerminals} />
        <Route path={`${url}/dealerCommissions/history`} component={DealerCommissionsHistory} />
        <Route path={`${url}/dealerCommissions/expectedChanges`} component={DealerCommissionsExpectedChanges} />
        <Route path={`${url}/suspendedFinancialTransactions`} component={SuspendedFinancialTransactions} />
        <Route path={`${url}/serviceManagement/paymentTypeNBUCode`} component={PaymentTypeNBUCode} />
        <Route path={`${url}/terminal_control/subBrands`} component={SubBrandsTerminals} />
        <Route path={`${url}/blockedTransactions`} component={BlockedTransactions} />
        <Route path={`${url}/mfoTransactionsPage`} component={MFOTransactionsPage} />

        <Route path={`${url}/externalOutgoingPayments`} component={ExternalOutgoingPaymentsPage} />
        <Route path={`${url}/allElements`} component={AllElementsPage} />
        <Route path={`${url}/suppliersBalance`} component={SuppliersBalance} />
        <Route path={`${url}/servicesAuthorizationLimits`} component={ServicesAuthorizationLimits} />
        <Route path={`${url}/checkTemplate/template`} component={CheckTemplate} />
        <Route path={`${url}/checkTemplate/services`} component={TemplateServices} />
        <Route path={`${url}/checkTemplate/terminals`} component={TemplateTerminals} />
        <Route path={`${url}/serviceManagement/paymentTypeManagement`} component={ManagementPaymentTypesPage} />
        <Route path={`${url}/serviceManagement/groupServices`} component={GroupServices} />
        <Route path={`${url}/serviceManagement/beneficiaries`} component={Beneficiaries} />
        <Route path={`${url}/serviceManagement/directors`} component={Directors} />
        <Route path={`${url}/cardRouting`} component={CardRouting} />
        <Route path={`${url}/managementMenu/menuProfiles`} component={ManagementMenu} />
        <Route path={`${url}/managementMenu/terminalProfiles`} component={TerminalProfiles} />
        <Route path={`${url}/managementMenu/audit`} component={MenuAudit} />
        <Route path={`${url}/managementMenu/elementPropertyDirectory`} component={MenuElementPropDictionary} />
        <Route path={`${url}/managementMenu/navigationMenuProp`} component={NavigationMenuProp} />
        <Route path={`${url}/easyPayUsers`} component={EasyPayUsers} />
        <Route path={`${url}/dictionaryManagement/errorCodes`} component={ManagementErrorCodes} />
        <Route path={`${url}/partnerInfoSender`} component={PartnerInfoSender} />
        <Route path={`${url}/manageMerchantCallbacks`} component={ManageMerchantCallbacks} />
        <Route path={`${url}/reports/byPaymentTypes`} component={ReportByPaymentTypes} />
        <Route path={`${url}/dictionaryManagement/MFO`} component={MFO} />
        <Route path={`${url}/dictionaryManagement/technicalPartners`} component={TechnicalPartners} />
        <Route path={`${url}/dictionaryManagement/remoteTerminalSettings`} component={PageRemoteTerminalSettings} />
        <Route path={`${url}/serviceManagement/serviceSupplier`} component={ServiceSuppliers} />
        <Route path={`${url}/managementMenu/navigationMenuElements`} component={NavigationMenuElements} />
        <Route path={`${url}/reports/fiscalizedTransactionsByDays`} component={FiscalizedTransactionsDaysReport} />
        <Route path={`${url}/reports/transactionsWithCashback`} component={TransactionsWithCashback} />
        <Route
          path={`${url}/reports/fiscalizedTransactionsByDaysByOwners`}
          component={FiscalizedTransactionsOwnersDaysReport}
        />
        <Route path={`${url}/terminalsActiveServices`} component={TerminalsActiveServices} />
        <Route path={`${url}/reports/unsuccessfulPaymentsAnalysis`} component={UnsuccessfulPaymentsAnalysis} />
        <Route path={`${url}/clientCabinet`} component={ClientCabinetPage} />

        <Route path={`${url}/gateWay/PayerPhone&CardNumber`} component={PayerPhoneAndCardNumber} />
        <Route component={NotFound} />
      </Switch>
    )}
  />
);

export default PagesRouting;
