import React, { PureComponent } from 'react';
import { Button, message, Modal } from 'antd';

import { withTranslate } from '../../contexts/localContext';
import LoadingPanel from '../../components/loader';
import { ApiService } from '../../services';

const api = new ApiService();

class DeleteServiceDialog extends PureComponent {
  state = {
    isLoading: false,
  };

  deleteServices = async () => {
    const { translate, closeDialog, onRefresh, selectedServices } = this.props;

    try {
      await this.setState({ isLoading: true });
      await api.deleteGatewayRuleException({
        serviceIds: selectedServices.map(({ ServiceId }) => ServiceId),
      });
      this.setState({
        isLoading: false,
      });
      message.success(translate('page.payerPhoneAndCardNumber.deleteSuccessfully'), 2);
      onRefresh();
      closeDialog();
    } catch (error) {
      const { showError } = this.props;
      showError(error);
      await this.setState({ isLoading: false });
    }
  };

  render() {
    const { translate, closeDialog, visible } = this.props;
    const { isLoading } = this.state;

    return (
      <Modal
        title={translate('page.payerPhoneAndCardNumber.deleteRules')}
        visible={visible}
        onCancel={isLoading ? () => {} : closeDialog}
        footer={[
          <Button type="primary" onClick={this.deleteServices}>
            {translate('core.buttonTitles.delete')}
          </Button>,
          <Button onClick={closeDialog}>{translate('core.buttonTitles.refuse')}</Button>,
        ]}
      >
        <div style={{ textAlign: 'center' }}>{translate('page.payerPhoneAndCardNumber.areYouSureDelete')}</div>
        {isLoading && <LoadingPanel />}
      </Modal>
    );
  }
}

export default withTranslate(DeleteServiceDialog);
