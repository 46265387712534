class UserServicesAccessService {
  mergeServicesWithPermission = (services, permissions) => {
    if (!services || !services.length) {
      return [];
    }

    if (!permissions || !permissions.length) {
      return services;
    }

    return services.map((item) => {
      const itemsPermission = permissions.find(({ ServiceId }) => ServiceId === item.ServiceId);

      return { ...itemsPermission, ...item };
    });
  };
}

export default new UserServicesAccessService();
