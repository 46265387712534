import React, { Component } from 'react';
import { Grid, GridColumn, GridColumnMenuFilter } from '@progress/kendo-react-grid';
import { filterBy, orderBy } from '@progress/kendo-data-query';

import { withTranslate } from '../../../contexts/localContext';
import { FilterScroll } from '../../grids/filters';
import { TRANSLATE_SLUG_BY_KEY } from './constants';
import CellWithCheckBox from '../CellCheckBoxElements/CellCheckBoxElements';

function localCompare(a, b) {
  return a.webTag.localeCompare(b.webTag);
}

function checkLatinSymbols(str) {
  return /[a-zA-Z]/.test(str);
}

class GridPages extends Component {
  state = {
    dataState: {
      take: 40,
      filter: {
        logic: 'and',
        filters: [],
      },
      sort: [],
    },
    view: [],
    data: [],
    selectedWebtag: null,
  };

  onDateChange = async () => {
    try {
      const { dataState } = this.state;
      await this.onInit();
      this.onDataStateChange({ data: dataState });
    } catch (error) {
      const { showError } = this.props;
      showError(error);
    }
  };

  componentDidMount() {
    this.onDateChange();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { dataState } = this.state;

    if (!prevProps.webTagList || prevProps.webTagList !== this.props.webTagList) {
      const data = this.setTranslation(this.props.webTagList);

      this.setState({
        view: filterBy(orderBy(data, dataState.sort), dataState.filter),
        data,
      });
    }
  }

  onDataStateChange = ({ data: dataState }) => {
    const { data } = this.state;

    this.setState({ dataState, view: filterBy(orderBy(data, dataState.sort), dataState.filter) });
  };

  checkFilterActivity = (field, dataState) => {
    const { filter } = dataState;

    if (!filter || !filter.filters.length) {
      return '';
    }

    const isElementActiveByFilters = filter.filters.find((element) => {
      return element.field === field;
    });

    return isElementActiveByFilters || GridColumnMenuFilter.active(field, dataState.filter) ? 'active' : '';
  };

  setTranslation = (webTagList) => {
    const { withTagsReleaseInfo } = this.props;

    return (
      webTagList
        .map((item) => ({ ...item, translatedWebTag: this.handleTranslate(item.webTag) }))
        .filter(({ translatedWebTag }) => translatedWebTag !== 'unknow_translate_slug')
        // .filter((el) => !checkLatinSymbols(el.translatedWebTag))
        .map((item) => ({
          ...item,
          translatedWebTag: withTagsReleaseInfo
            ? `${item.translatedWebTag} (${item.ReleaseVersion})`
            : item.translatedWebTag,
        }))
    );
  };

  onInit = async () => {
    const { webTagList } = this.props;
    const data = webTagList.sort(localCompare);

    this.setState(
      {
        data: this.setTranslation(data),
      },
      () => this.setInitialSelected(data),
    );
  };

  setInitialSelected = (data) => {
    const { onHandleRowClick } = this.props;

    if (data && data.length) {
      this.setState(
        {
          selectedWebtag: data[0].webTag,
        },
        () => onHandleRowClick(data[0].webTag),
      );
    }
  };

  onRowClick = (e) => {
    const currentDataItem = e.dataItem || e;
    const { onHandleRowClick } = this.props;
    this.setState({ selectedWebtag: currentDataItem.webTag }, onHandleRowClick(currentDataItem.webTag));
  };

  rowRender = (element, { dataItem: { checked } }) => {
    const { style: elementStyle, children } = element.props;

    return React.cloneElement(
      element,
      { ...elementStyle, ...(checked ? {} : { pointerEvents: 'none', background: 'lightgrey' }) },
      children,
    );
  };

  handleTranslate = (value) => {
    const { translate } = this.props;

    const translateSlugs = TRANSLATE_SLUG_BY_KEY.get(value) || ['unknow_translate_slug'];

    return translateSlugs.reduce((acc, slug) => `${acc}: ${translate(slug)}`, '').slice(2);
  };

  render() {
    const { dataState, view, isMobile, selectedWebtag } = this.state;
    const { translate, onWebtagCheckboxClick, webTagList } = this.props;

    if (!webTagList.length) {
      return null;
    }

    return (
      <Grid
        data={view.map((item) => ({
          ...item,
          selected: item.webTag === selectedWebtag,
        }))}
        resizable
        pageSize={dataState.take}
        filterable
        sortable
        rowHeight={40}
        filter={dataState.filter}
        sort={dataState.sort}
        total={view.length}
        selectedField="selected"
        onRowClick={this.onRowClick}
        rowRender={this.rowRender}
        onDataStateChange={this.onDataStateChange}
        className={`dropdown-owner  ${isMobile ? 'mobileMode' : ''} admin-grids`}
      >
        <GridColumn
          field="translatedWebTag"
          title={translate('grids.adminPanel.column-page')}
          filter="text"
          width={500}
          headerClassName={this.checkFilterActivity('translatedWebTag', dataState)}
          {...(onWebtagCheckboxClick
            ? {
                cell: (props) => (
                  <CellWithCheckBox
                    onRowClickCallback={this.onRowClick}
                    onRowCheckboxClickCallback={onWebtagCheckboxClick}
                    {...props}
                  />
                ),
              }
            : {})}
        />
      </Grid>
    );
  }
}

export default withTranslate(GridPages);
