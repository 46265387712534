const ServiceUserAccess = (translate, value) => {
  switch (value) {
    case 'serviceUserAccess-content':
      return `${translate(`page.adminPanel.pageContent`)}`;
    case 'serviceUserAccess-history':
      return `${translate(`page.adminPanel.type-navLink`)}: ${translate('page.serviceUserAccess.historyChanges')}`;
    default:
      return value;
  }
};

export default ServiceUserAccess;
