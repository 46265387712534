import React from 'react';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Sortable } from '@progress/kendo-react-sortable';
import SortableItemUI from '../sortableItemUI';
import { withTranslate } from '../../../contexts/localContext';

const PopupGroupDialog = ({
  toggleCloseGroupDialog,
  dialogGroupSettingsTree,
  onSwitchChangeGroup,
  onTreeOkGroup,
  translate,
  filterTreeViewBySpecificFields,
  onDragEndGroup,
}) => {
  const sortFunc = (a, b) => {
    return a.orderIndex - b.orderIndex;
  };

  const data = dialogGroupSettingsTree
    .map((el) => {
      return {
        ...el,
        text: translate(el.text),
        orderIndex: el.locked ? el.orderIndex : dialogGroupSettingsTree.length,
      };
    })
    .filter((el) => filterTreeViewBySpecificFields(el))
    .filter((el) => el.field !== 'selected')
    .sort(sortFunc);

  return (
    <Dialog title={translate('base-grid.grouping')} onClose={toggleCloseGroupDialog}>
      <Sortable
        idField={'field'}
        data={data}
        itemUI={(props) => (
          <SortableItemUI isOnlySwitch onSwitchChange={() => onSwitchChangeGroup(props.dataItem)} {...props} />
        )}
        onDragEnd={onDragEndGroup}
      />
      <DialogActionsBar>
        <button className="k-button k-primary" onClick={onTreeOkGroup}>
          Ok
        </button>
        <button className="k-button k-primary" onClick={toggleCloseGroupDialog}>
          {translate('base-grid.rebuild')}
        </button>
      </DialogActionsBar>
    </Dialog>
  );
};

export default withTranslate(PopupGroupDialog);
