export const SEARCH_TYPES = [
  { id: 'inn', translateTitle: 'page.beneficiaries.inn' },
  { id: 'phone', translateTitle: 'page.easyPayUsers.phone' },
  { id: 'fio', translateTitle: 'page.serviceSupplier.fullName' },
  { id: 'userId', translateTitle: 'page.profilesMenu.userId' },
];

export const TRANSACTION_FIELDS_INFO_CLIENT_CABINET = {
  ownerName: { filter: 'text', title: 'grids.transactions.column-ownerName' },
  transactionId: { filter: 'numeric', title: 'grids.transactions.column-transactionId' },
  requestId: { filter: 'numeric', title: 'grids.transactions.column-requestId' },
  terminalId: { filter: 'numeric', title: 'grids.transactions.column-terminalId' },
  serviceId: { filter: 'numeric', title: 'grids.transactions.column-serviceId' },
  serviceName: { filter: 'text', title: 'grids.transactions.column-serviceName' },
  amountTotal: { filter: 'numeric', title: 'grids.transactions.column-amountTotal' },
  amountOriginal: { filter: 'numeric', title: 'grids.transactions.column-amountOriginal' },
  commissionProvider: { filter: 'numeric', title: 'grids.transactions.column-comissionProvider' },
  commissionClient: { filter: 'numeric', title: 'grids.transactions.column-comissionClient' },
  currency: { filter: 'text', title: 'grids.transactions.column-currency' },
  dateInput: { filter: 'date', title: 'grids.transactions.column-dateInput' },
  datePost: { filter: 'date', title: 'grids.transactions.column-datePost' },
  dateDeclined: { filter: 'date', title: 'grids.transactions.column-dateDeclined' },
  dateAccepted: { filter: 'date', title: 'grids.transactions.column-dateAccepted' },
  account: { filter: 'text', title: 'grids.transactions.column-account' },
  comment: { filter: 'text', title: 'grids.transactions.column-comment' },
  statusName: { filter: 'text', title: 'grids.transactions.column-status' },
  paymentType: { filter: 'text', title: 'grids.transactions.column-typePayment' },
  operationName: { filter: 'text', title: 'page.transactions.operation' },
  terminalType: { filter: 'text', title: 'page.transactions.terminalType' },
};

export const CustomPropsSlug = 'page.clientCabinet.CustomProps';
export const NotTransactionPropertySlug = 'page.clientCabinet.notTransactionProperty';

export const TRANSACTION_PROPS = [
  NotTransactionPropertySlug,
  CustomPropsSlug,
  'Account',
  'Amount',
  'BatchId',
  'Card.Amount',
  'Card.Account',
  'Card.Hash',
  'Card.Pan',
  'Card.Track2',
  'HoldRuleId',
  'HoldWhiteList',
  'Debtor.Code',
  'ErrorCode',
  'ErrorMessage',
  'Phone',
  'PayerPhone',
  'Rrn',
  'ServiceId',
  'Track2',
  'TransactionId',
  'UserId',
];
