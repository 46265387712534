import React, { PureComponent } from 'react';
import { withTranslate } from '../../contexts/localContext';
import { Button, Col, message, Modal } from 'antd';

import Grid from '../../components/grids/baseGrid';
import { GridColumn } from '@progress/kendo-react-grid';
import { ApiService, tabService } from '../../services';

import './ManagementPaymentTypes.scss';
import ModalManagementPaymentTypes from './ModalManagementPaymentTypes/ModalManagementPaymentTypes';
import Normalizer from './normalizer';
import GridDropdown from '../../components/GridDropdown/GridDropdown';
import {
  BASE_SERVICES_COLUMN_CONFIG,
  OWNERS_COLUMN_CONFIG,
  PAYMENT_TYPES_OWNER_COL_CONFIG,
} from '../../core/constants/configs.const';
import DropdownNormalizersService from '../../core/normalizers/dropdownNormalizers.service';

import findArray from '../../utils/findArrForCurrentPage';
import checkElement from '../../utils/checkElement';
import CellBoolean from '../account/cellBoolean';

const api = new ApiService();

export const WrappedCell = (props) => {
  const {
    dataItem: { Allowed },
  } = props;
  return <td style={{ textAlign: 'center' }}>{Allowed ? 'Allow' : 'Deny'}</td>;
};
export const WrappedCellBool = (props) => <CellBoolean {...props} style={{ textAlign: 'center' }} />;

class ManagementPaymentTypes extends PureComponent {
  constructor(props) {
    super(props);
    this.currentArray = findArray('paymentTypesManagement');
  }

  state = {
    allOwners: [],
    services: [],
    paymentTypes: [],
    terminals: [],
    dataGrid: [],
    selectPaymentTypes: [],
    selectServices: [],
    selectOwners: [1],
    selectedItems: [],
    selectItems: [],
    selectTerminals: [],
    paymentTypeCode: [],
    isLoading: false,
    nameModal: '',
  };

  toggleLoader = (value) => {
    this.setState({ isLoading: value });
  };

  fetchOwners = async () => {
    try {
      await this.toggleLoader(true);
      const allOwners = await api.owners();
      this.setState({ allOwners: Normalizer.normalizeData({ OwnerId: 0, ShortName: 'Всі дилери' }, allOwners) });
      await this.toggleLoader(false);
    } catch (e) {
      this.handleErrors(e);
    }
  };

  fetchServicesList = async () => {
    const { selectOwners } = this.state;
    const isFetchAllServices = selectOwners[0] === 0 ? {} : { ServiceId: -1, ServiceName: 'Всі сервіси' };
    try {
      const model = {
        ownerId: selectOwners,
      };
      await this.toggleLoader(true);
      const services = await api.getServiceDictionary(model);

      const normalizedServices = DropdownNormalizersService.normalizeServices(
        Normalizer.normalizeData(isFetchAllServices, services),
      );
      this.setState({ services: normalizedServices });
      await this.toggleLoader(false);
    } catch (e) {
      this.handleErrors(e);
    }
  };

  fetchPaymentType = async () => {
    const { selectOwners } = this.state;

    try {
      await this.toggleLoader(true);
      const paymentTypes = await api.ownersPaymentTypeList(selectOwners);

      const normalizePaymentTypes = DropdownNormalizersService.normalizePaymentTypeCode(paymentTypes);

      await this.setState({ paymentTypes: normalizePaymentTypes });
      await this.toggleLoader(false);
    } catch (e) {
      this.handleErrors(e);
    }
  };

  fetchGetTerminalsServicesPaymentTypes = async () => {
    const { selectOwners, selectServices, selectPaymentTypes } = this.state;
    if (!(selectServices.length && selectServices.length && selectPaymentTypes.length)) return;

    try {
      const model = {
        ownerId: selectOwners,
        serviceId: selectServices,
        paymentType: selectPaymentTypes,
      };
      await this.toggleLoader(true);
      const dataGrid = await api.getTerminalsServicesPaymentTypes(model);
      this.setState({ dataGrid });
      await this.toggleLoader(false);
    } catch (e) {
      this.handleErrors(e);
    }
  };

  dropDownChangeCallback = (name) => (value) => {
    this.setState({ [name]: value }, async () => {
      if (name === 'selectOwners') {
        this.setState({ selectServices: [], selectPaymentTypes: [] });
        await this.fetchPaymentType();
        await this.fetchServicesList();
      }
    });
  };

  onHandleClick = (e) => {
    const { name } = e.target;
    switch (name) {
      case 'add':
        return this.toggleModal('addModal');
      case 'delete':
        return this.toggleModal('deleteModal');
      case 'change':
        return this.toggleModal('changeModal');
      case 'history':
        tabService.addTab({
          type: 'history',
        });
    }
  };

  toggleModal = (nameModal) => {
    this.setState({ nameModal: nameModal });
  };

  deleteItems = async () => {
    const { selectedItems } = this.state;
    const ruleId = selectedItems.map(({ RuleId }) => RuleId);
    try {
      await this.toggleLoader(true);
      await api.terminalsServicesPaymentTypesDelete(ruleId[0]);
      await this.showMessage('msg-successfully-deleted');
      this.toggleModal('');
      await this.toggleLoader(false);
      await this.fetchGetTerminalsServicesPaymentTypes();
    } catch (e) {
      this.handleErrors(e);
    }
  };

  changeStatus = async (changeStatus) => {
    const { selectedItems } = this.state;
    try {
      const model = selectedItems.map(({ Allowed, OwnerId, PaymentType, RuleId, ServiceId, TerminalId }) => ({
        Allowed,
        Enabled: changeStatus,
        OwnerId,
        PaymentType,
        RuleId,
        ServiceId,
        TerminalId,
      }));
      await this.toggleLoader(true);
      await api.terminalsServicesPaymentTypesUpdate(model[0]);
      await this.showMessage('msg-successfully-changed');
      await this.toggleLoader(false);
      await this.toggleModal('');
      await this.fetchGetTerminalsServicesPaymentTypes();
    } catch (e) {
      this.handleErrors(e);
    }
  };
  handleErrors = (err) => {
    const { showError } = this.props;
    this.toggleLoader(false);
    showError(err);
  };

  showMessage = (msg) => {
    const { translate } = this.props;
    return message.success(`${translate(`page.managementPaymentTypes.${msg}`)}`, 2.5);
  };

  fetchAllData = async () => {
    await this.fetchOwners();
    await this.fetchPaymentType();
    await this.fetchServicesList();
  };

  componentDidMount() {
    this.fetchAllData();
  }

  buildToolbar = () => {
    const { translate } = this.props;
    const { allOwners, services, paymentTypes, selectedItems, selectPaymentTypes, selectServices, selectOwners } =
      this.state;

    return (
      <Col className="ManagementPaymentTypes-toolbar">
        <div className="ManagementPaymentTypes-row ManagementPaymentTypes-row-aligent-center">
          <div className="ManagementPaymentTypes-col">
            <div className="ManagementPaymentTypes-row ManagementPaymentTypes-row-aligent-center">
              <div className="ManagementPaymentTypes-label">{translate('page.commissionsServices.dealer')}</div>
              <div className="ManagementPaymentTypes-dropDown">
                <GridDropdown
                  data={DropdownNormalizersService.normalizeOwners(allOwners)}
                  colConfig={OWNERS_COLUMN_CONFIG}
                  selectItems={selectOwners}
                  defaultTitle={translate('page.dealerCommissions.selectDealer')}
                  onSave={this.dropDownChangeCallback('selectOwners')}
                  isSingle
                />
              </div>
            </div>
            <div className="ManagementPaymentTypes-row ManagementPaymentTypes-row-aligent-center">
              <div className="ManagementPaymentTypes-label">{translate('page.commissionsServices.service')}</div>
              <div className="ManagementPaymentTypes-dropDown">
                <GridDropdown
                  data={services}
                  colConfig={BASE_SERVICES_COLUMN_CONFIG}
                  selectItems={selectServices}
                  defaultTitle={translate('page.commissionsServices.chooseService')}
                  onSave={this.dropDownChangeCallback('selectServices')}
                  isSingle
                />
              </div>
            </div>
            <div className="ManagementPaymentTypes-row ManagementPaymentTypes-row-aligent-center">
              <div className="ManagementPaymentTypes-label">{translate('page.commissionsServices.paymentType')}</div>
              <div className="ManagementPaymentTypes-dropDown">
                <GridDropdown
                  data={paymentTypes}
                  colConfig={PAYMENT_TYPES_OWNER_COL_CONFIG}
                  selectItems={selectPaymentTypes}
                  defaultTitle={translate('page.commissionsServices.choosePaymentType')}
                  onSave={this.dropDownChangeCallback('selectPaymentTypes')}
                />
              </div>
            </div>
          </div>
          <Button
            type="primary"
            disabled={!(selectPaymentTypes.length && selectServices.length && selectOwners.length)}
            onClick={this.fetchGetTerminalsServicesPaymentTypes}
          >
            {translate('core.buttonTitles.search')}
          </Button>
        </div>

        <div className="ManagementPaymentTypes-row">
          <Button.Group className="ManagementPaymentTypes-btnGroup" onClick={this.onHandleClick}>
            {checkElement('paymentTypesManagement-btnAdd', this.currentArray) && (
              <Button name="add" type="primary">
                {translate('core.buttonTitles.add')}
              </Button>
            )}
            {checkElement('paymentTypesManagement-btnDelete', this.currentArray) && (
              <Button name="delete" type="primary" disabled={!selectedItems.length}>
                {translate('core.buttonTitles.delete')}
              </Button>
            )}
            {checkElement('paymentTypesManagement-btnChange', this.currentArray) && (
              <Button name="change" type="primary" disabled={!selectedItems.length}>
                {translate('core.buttonTitles.change')}
              </Button>
            )}
            {checkElement('paymentTypesManagement-history', this.currentArray) && (
              <Button name="history" type="primary">
                {translate('page.managementPaymentTypes.changesHistory')}
              </Button>
            )}
          </Button.Group>
        </div>
      </Col>
    );
  };

  onSelectItems = (selectedItems) => {
    this.setState({ selectedItems });
  };

  render() {
    const { isLoading, dataGrid = [], nameModal } = this.state;
    const { translate } = this.props;
    if (!checkElement('paymentTypesManagement-content', this.currentArray)) {
      return null;
    }
    return (
      <>
        <Grid
          data={dataGrid}
          name="managementPaymentTypesGrid"
          toolbar={this.buildToolbar()}
          onRefresh={this.fetchGetTerminalsServicesPaymentTypes}
          fieldForSelection="RuleId"
          onSelect={this.onSelectItems}
          selectable={false}
          isLoading={isLoading}
        >
          <GridColumn
            field="selected"
            width="50px"
            filterable={false}
            sortable={false}
            showAllSelected={false}
            columnMenu={false}
          />
          <GridColumn field="OwnerName" title="grids.managementPaymentTypes.col-dealer" width="150px" />
          <GridColumn field="ServiceId" title="grids.managementPaymentTypes.col-serviceId" width="150px" />
          <GridColumn field="ServiceName" title="grids.managementPaymentTypes.col-group-service" width="200px" />
          <GridColumn field="PaymentTypeName" title="grids.managementPaymentTypes.col-payment-type" width="150px" />
          <GridColumn
            field="Allowed"
            title="grids.managementPaymentTypes.col-active-payment-type"
            width="200px"
            cell={(props) => WrappedCell(props)}
          />
          <GridColumn
            field="Enabled"
            title="grids.managementPaymentTypes.col-status-rule"
            width="150px"
            cell={WrappedCellBool}
          />
          <GridColumn field="TerminalId" title="grids.managementPaymentTypes.col-terminal" width="150px" />
          <GridColumn field="RuleId" title="grids.managementPaymentTypes.col-ruleId" width="150px" />
        </Grid>
        {nameModal === 'addModal' && (
          <ModalManagementPaymentTypes
            visible={nameModal === 'addModal'}
            showMessage={this.showMessage}
            closeModal={this.toggleModal}
            fetchServicesList={this.fetchServicesList}
            handleErrors={this.handleErrors}
            toggleLoader={this.toggleModal}
          ></ModalManagementPaymentTypes>
        )}

        <Modal
          visible={nameModal === 'deleteModal'}
          title={translate('page.managementPaymentTypes.modal-delete-title')}
          onCancel={() => this.toggleModal('')}
          footer={[
            <Button type="danger" onClick={this.deleteItems}>
              {translate('core.buttonTitles.ok')}
            </Button>,
            <Button onClick={() => this.toggleModal('')}>{translate('core.buttonTitles.refuse')}</Button>,
          ]}
        >
          {translate('page.managementPaymentTypes.modal-delete-content')}
        </Modal>

        <Modal
          visible={nameModal === 'changeModal'}
          title={translate('page.managementPaymentTypes.modal-change-title')}
          onCancel={() => this.toggleModal('')}
          footer={[
            <Button type="primary" onClick={() => this.changeStatus(true)}>
              {translate('core.buttonTitles.turnOn-action')}
            </Button>,
            <Button type="danger" onClick={() => this.changeStatus(false)}>
              {translate('core.buttonTitles.turnOff-action')}
            </Button>,
          ]}
        ></Modal>
      </>
    );
  }
}

export default withTranslate(ManagementPaymentTypes);
