import React, { PureComponent } from 'react';
import { Button, Row } from 'antd';
import { GridColumn } from '@progress/kendo-react-grid';

import Grid from '../../components/grids/baseGrid';
import { withTranslate } from '../../contexts/localContext';
import { ApiService, tabService } from '../../services';
import AddServiceDialog from './AddServiceDialog';
import DeleteServiceDialog from './DeleteServiceDialog';

import './PayerPhoneAndCardNumberGrid.styled.scss';
import findArray from '../../utils/findArrForCurrentPage';
import checkElement from '../../utils/checkElement';
import { getDataByTimezoneOffset } from '../../utils/getDataByTimezoneOffset';

const api = new ApiService();

class PayerPhoneAndCardNumberGrid extends PureComponent {
  currentArray = findArray('checkPayerPhoneAndCardNumber');

  state = {
    services: [],
    selectedServices: [],
    isLoading: false,
    dialogType: '',
  };

  componentDidMount() {
    this.fetchServices();
  }

  buildToolbar = () => {
    const { translate } = this.props;
    const { selectedServices } = this.state;

    return (
      <Row className="PayerPhoneAndCardNumberGrid-toolbar">
        {checkElement('checkPayerPhoneAndCardNumber-add', this.currentArray) && (
          <div>
            <Button
              type="primary"
              onClick={() => {
                this.setState({ dialogType: 'add' });
              }}
            >
              {translate('core.buttonTitles.add')}
            </Button>
          </div>
        )}

        {checkElement('checkPayerPhoneAndCardNumber-delete', this.currentArray) && (
          <div>
            <Button
              type="primary"
              onClick={() => {
                this.setState({ dialogType: 'delete' });
              }}
              disabled={!selectedServices.length}
            >
              {translate('core.buttonTitles.delete')}
            </Button>
          </div>
        )}

        {checkElement('checkPayerPhoneAndCardNumber-history', this.currentArray) && (
          <div>
            <Button
              type="primary"
              onClick={() => {
                tabService.addTab({
                  type: 'history',
                });
              }}
            >
              {translate('page.payerPhoneAndCardNumber.changesHistory')}
            </Button>
          </div>
        )}
      </Row>
    );
  };

  fetchServices = async () => {
    try {
      await this.setState({ isLoading: true });
      const services = await api.getGatewayRuleException();
      this.setState({
        isLoading: false,
        services: services.map(({ DatePostFrom, ...restData }) => ({
          ...restData,
          DatePostFrom: getDataByTimezoneOffset(new Date(DatePostFrom)).toISOString(),
        })),
      });
    } catch (error) {
      const { showError } = this.props;
      showError(error);
      await this.setState({ isLoading: false });
    }
  };

  render() {
    const { services, isLoading, dialogType, selectedServices } = this.state;

    if (!checkElement('checkPayerPhoneAndCardNumber-content', this.currentArray)) {
      return null;
    }

    return (
      <>
        <Grid
          data={services}
          name="payerPhoneAndCardNumberGrid"
          toolbar={this.buildToolbar()}
          onRefresh={this.fetchServices}
          isLoading={isLoading}
          fieldForSelection="ServiceId"
          onSelect={(selectedServices) => {
            this.setState({ selectedServices });
          }}
          multiSelected
        >
          <GridColumn field="selected" width="50px" filterable={false} columnMenu={false} showAllSelected={true} />
          <GridColumn
            field="ServiceId"
            title="grids.servicesBigTable.column-serviceId"
            width="150px"
            filter="numeric"
          />
          <GridColumn field="ServiceName" title="page.commissionsServices.col-serviceName" width="480" />
          <GridColumn field="F108" title="page.OTPServices.col-NBU" width="150" />
          <GridColumn
            field="DatePostFrom"
            title="page.OTPServices.col-date"
            width="160"
            filter="date"
            format="dd.MM.yyyy HH:mm:ss"
          />
          <GridColumn field="UserName" title="page.OTPServices.col-performer" width="240" />
        </Grid>

        {dialogType === 'add' && (
          <AddServiceDialog
            visible={dialogType === 'add'}
            closeDialog={() => {
              this.setState({ dialogType: '' });
            }}
            onRefresh={this.fetchServices}
          />
        )}

        {dialogType === 'delete' && (
          <DeleteServiceDialog
            visible={dialogType === 'delete'}
            closeDialog={() => {
              this.setState({ dialogType: '' });
            }}
            onRefresh={this.fetchServices}
            selectedServices={selectedServices}
          />
        )}
      </>
    );
  }
}

export default withTranslate(PayerPhoneAndCardNumberGrid);
