import React, { PureComponent } from 'react';
import { Button, Col, Row } from 'antd';
import { GridColumn } from '@progress/kendo-react-grid';
import { DatePicker } from '@progress/kendo-react-dateinputs';

import Grid from '../../components/grids/baseGrid';
import { WrappedCell, WrappedCellBool } from './ManagmentPaymentTypes';
import GridDropdown from '../../components/GridDropdown/GridDropdown';
import DropdownNormalizersService from '../../core/normalizers/dropdownNormalizers.service';
import { withTranslate } from '../../contexts/localContext';
import { ApiService } from '../../services';
import ManagementPaymentTypesService from './ManagmentPaymentTypes.service';
import { StandardizeTimeForDate } from '../../utils/standardizeTimeForDate';
import {
  BASE_SERVICES_COLUMN_CONFIG,
  OWNERS_COLUMN_CONFIG,
  PAYMENT_TYPES_OWNER_COL_CONFIG,
} from '../../core/constants/configs.const';

import './ManagementPaymentTypes.scss';
import { getDataByTimezoneOffset } from '../../utils/getDataByTimezoneOffset';
import findArray from '../../utils/findArrForCurrentPage';

const api = new ApiService();

class ManagementPaymentTypesHistory extends PureComponent {
  state = {
    history: [],
    isLoading: false,
    selectOwners: [1],
    allOwners: [],
    services: [],
    selectServices: [],
    paymentTypes: [],
    selectPaymentTypes: [],
    dateStart: StandardizeTimeForDate(new Date(), true),
    dateEnd: StandardizeTimeForDate(new Date()),
    errorMessage: '',
  };

  componentDidMount() {
    this.fetchAllData();
  }

  fetchAllData = async () => {
    await this.setState({ isLoading: true });
    await this.fetchOwners();
    await this.fetchPaymentType();
    await this.fetchServicesList();
    await this.setState({ isLoading: false });
  };

  handleErrors = (err) => {
    const { showError } = this.props;
    this.setState({ isLoading: false });
    showError(err);
  };

  fetchPaymentType = async () => {
    const { selectOwners } = this.state;

    try {
      const paymentTypes = await api.ownersPaymentTypeList(selectOwners);

      await this.setState({ paymentTypes: DropdownNormalizersService.normalizePaymentTypeCode(paymentTypes) });
    } catch (e) {
      this.handleErrors(e);
    }
  };

  fetchOwners = async () => {
    try {
      const allOwners = await api.owners();

      this.setState({ allOwners });
    } catch (e) {
      this.handleErrors(e);
    }
  };

  fetchServicesList = async () => {
    const { selectOwners, allOwners } = this.state;

    try {
      const model = {
        ownerId: selectOwners.length === allOwners.length ? [0] : selectOwners,
      };

      const services = await api.getServiceDictionary(model);

      this.setState({ services: DropdownNormalizersService.normalizeServices(services) });
    } catch (e) {
      this.handleErrors(e);
    }
  };

  dropDownChangeCallback = (name) => (value) => {
    this.setState({ [name]: value }, async () => {
      if (name === 'selectOwners') {
        await this.setState({ selectServices: [], selectPaymentTypes: [], isLoading: true });
        await this.fetchPaymentType();
        await this.fetchServicesList();
        await this.setState({ isLoading: false });
      }
    });
  };

  fetchGetTerminalsServicesPaymentTypesHistory = async () => {
    const {
      selectPaymentTypes,
      selectServices,
      selectOwners,
      errorMessage,
      dateStart,
      dateEnd,
      allOwners,
      services,
      paymentTypes,
    } = this.state;

    if (!!errorMessage || !selectPaymentTypes.length || !selectServices.length || !selectOwners.length) {
      return;
    }

    try {
      await this.setState({ isLoading: true });

      const history = await api.getTerminalsServicesPaymentTypesHistory({
        ownerId: selectOwners.length === allOwners.length ? [0] : selectOwners,
        serviceId: selectServices.length === services.length ? [-1] : selectServices,
        paymentType: selectPaymentTypes.length === paymentTypes.length ? [] : selectPaymentTypes,
        dateFrom: getDataByTimezoneOffset(StandardizeTimeForDate(new Date(dateStart), true)).toISOString(),
        dateTo: getDataByTimezoneOffset(StandardizeTimeForDate(new Date(dateEnd))).toISOString(),
      });

      await this.setState({
        isLoading: false,
        history: history.map(({ DateFrom, DateTo, ...restData }) => ({
          ...restData,
          DateFrom: getDataByTimezoneOffset(new Date(DateFrom)).toISOString(),
          DateTo: getDataByTimezoneOffset(new Date(DateTo)).toISOString(),
        })),
      });
    } catch (e) {
      this.handleErrors(e);
    }
  };

  getDateChangeCallback = (fieldName) => (e) => {
    const { translate } = this.props;

    this.setState(
      {
        [fieldName]: e.target.value,
        errorMessage: '',
      },
      () => {
        this.setState(({ dateStart, dateEnd }) => ({
          errorMessage: ManagementPaymentTypesService.getSubmitErrorsByDate(dateStart, dateEnd, translate),
        }));
      },
    );
  };

  buildToolbar = () => {
    const { translate } = this.props;
    const {
      allOwners,
      services,
      paymentTypes,
      selectPaymentTypes,
      selectServices,
      selectOwners,
      dateEnd,
      dateStart,
      errorMessage,
    } = this.state;

    return (
      <Col className="ManagementPaymentTypes-toolbar">
        <div className="ManagementPaymentTypes-row ManagementPaymentTypes-row-aligent-center">
          <div className="ManagementPaymentTypes-col">
            <div className="ManagementPaymentTypes-row ManagementPaymentTypes-row-aligent-center">
              <div className="ManagementPaymentTypes-label">{translate('page.commissionsServices.dealer')}</div>
              <div className="ManagementPaymentTypes-dropDown">
                <GridDropdown
                  data={DropdownNormalizersService.normalizeOwners(allOwners)}
                  colConfig={OWNERS_COLUMN_CONFIG}
                  selectItems={selectOwners}
                  defaultTitle={translate('page.dealerCommissions.selectDealer')}
                  onSave={this.dropDownChangeCallback('selectOwners')}
                />
              </div>
            </div>
            <div className="ManagementPaymentTypes-row ManagementPaymentTypes-row-aligent-center">
              <div className="ManagementPaymentTypes-label">{translate('page.commissionsServices.service')}</div>
              <div className="ManagementPaymentTypes-dropDown">
                <GridDropdown
                  data={services}
                  colConfig={BASE_SERVICES_COLUMN_CONFIG}
                  selectItems={selectServices}
                  defaultTitle={translate('page.commissionsServices.chooseService')}
                  onSave={this.dropDownChangeCallback('selectServices')}
                />
              </div>
            </div>
            <div className="ManagementPaymentTypes-row ManagementPaymentTypes-row-aligent-center">
              <div className="ManagementPaymentTypes-label">{translate('page.commissionsServices.paymentType')}</div>
              <div className="ManagementPaymentTypes-dropDown">
                <GridDropdown
                  data={paymentTypes}
                  colConfig={PAYMENT_TYPES_OWNER_COL_CONFIG}
                  selectItems={selectPaymentTypes}
                  defaultTitle={translate('page.commissionsServices.choosePaymentType')}
                  onSave={this.dropDownChangeCallback('selectPaymentTypes')}
                />
              </div>
            </div>

            <div className="ManagementPaymentTypes-row ManagementPaymentTypes-row-aligent-center">
              <div className="ManagementPaymentTypes-dateRow">
                <div className="ManagementPaymentTypes-shortLabel">
                  {translate('page.changingProfileHistory.dateFrom')}
                </div>

                <DatePicker
                  format="yyyy.MM.dd"
                  onChange={this.getDateChangeCallback('dateStart')}
                  value={dateStart}
                  min={ManagementPaymentTypesService.getMinimumSelectedDate()}
                  max={StandardizeTimeForDate(new Date())}
                />
              </div>

              <div className="ManagementPaymentTypes-dateRow">
                <div className="ManagementPaymentTypes-shortLabel">
                  {translate('page.changingProfileHistory.dateTo')}
                </div>

                <DatePicker
                  format="yyyy.MM.dd"
                  onChange={this.getDateChangeCallback('dateEnd')}
                  value={dateEnd}
                  min={ManagementPaymentTypesService.getMinimumSelectedDate()}
                  max={StandardizeTimeForDate(new Date())}
                />
              </div>
            </div>
          </div>

          <Button
            type="primary"
            disabled={!(selectPaymentTypes.length && selectServices.length && selectOwners.length) || !!errorMessage}
            onClick={this.fetchGetTerminalsServicesPaymentTypesHistory}
          >
            {translate('core.buttonTitles.search')}
          </Button>
        </div>

        {errorMessage && <div className="ManagementPaymentTypes-error">{errorMessage}</div>}
      </Col>
    );
  };

  render() {
    const { history, isLoading } = this.state;

    return (
      <Grid
        data={history}
        name="managementPaymentTypesHistoryGrid"
        toolbar={this.buildToolbar()}
        onRefresh={this.fetchGetTerminalsServicesPaymentTypesHistory}
        isLoading={isLoading}
      >
        <GridColumn field="OwnerName" title="grids.managementPaymentTypes.col-dealer" width="150px" />
        <GridColumn field="ServiceId" title="grids.managementPaymentTypes.col-serviceId" width="150px" />
        <GridColumn field="ServiceName" title="grids.managementPaymentTypes.col-group-service" width="200px" />
        <GridColumn field="PaymentTypeName" title="grids.managementPaymentTypes.col-payment-type" width="150px" />
        <GridColumn
          field="Allowed"
          title="grids.managementPaymentTypes.col-active-payment-type"
          width="200px"
          cell={(props) => WrappedCell(props)}
        />
        <GridColumn
          field="Enabled"
          title="grids.managementPaymentTypes.col-status-rule"
          width="150px"
          cell={WrappedCellBool}
        />
        <GridColumn field="TerminalId" title="grids.managementPaymentTypes.col-terminal" width="150px" />
        <GridColumn
          field="DateFrom"
          title="grids.managementPaymentTypes.col-startDate"
          width="150px"
          filter="date"
          format="dd.MM.yyyy HH:mm:ss"
        />
        <GridColumn
          field="DateTo"
          title="grids.managementPaymentTypes.col-endDate"
          width="150px"
          filter="date"
          format="dd.MM.yyyy HH:mm:ss"
        />
        <GridColumn field="UserNamePost" title="grids.managementPaymentTypes.col-userPost" width="150px" />
        <GridColumn field="RuleId" title="grids.managementPaymentTypes.col-ruleId" width="150px" />
      </Grid>
    );
  }
}

export default withTranslate(ManagementPaymentTypesHistory);
