export const TRANSACTION_STATUSES = [
  { value: 0, title: 'все статусы' },
  { value: 1, title: 'введен' },
  { value: 2, title: 'принят' },
  { value: 3, title: 'отклонён' },
  { value: 4, title: 'удален' },
  { value: 5, title: 'обрабатывается' },
  { value: 6, title: 'заблокирован' },
  { value: 7, title: 'создан' },
  { value: 8, title: 'удержан' },
];
