import React from 'react';
import { Switch, Checkbox } from 'antd';
import './sortableItemUI.scss';
import { MenuOutlined } from '@ant-design/icons';

const SortableItemUI = (props) => {
  const {
    isDisabled,
    isActive,
    style,
    attributes,
    dataItem,
    forwardRef,
    onSwitchChange,
    onCheckChange,
    onDragEnd,
    isOnlySwitch = false,
  } = props;
  const classNames = ['col-xs-6 col-sm-3'];

  if (isDisabled) {
    classNames.push('k-state-disabled');
  }
  return (
    <div
      className={`${classNames.join(' ')}
      basegrid-sortable-item ${isActive && 'active'}`}
    >
      <div className="basegrid-sortable-item-span-wrapper">
        <div
          {...attributes}
          ref={forwardRef}
          style={{
            // ...getBaseItemStyle(isActive),
            ...style,
            touchAction: 'none',
          }}
          className="basegrid-sortable-drag"
        >
          <MenuOutlined style={{ fontSize: '24px' }} />
        </div>
        {!isOnlySwitch && <Checkbox checked={dataItem.checked} onChange={onCheckChange} />}
        <span>{dataItem.text}</span>
      </div>
      <Switch className="basegrid-sortable-item-switch" checked={dataItem.locked} onClick={onSwitchChange} />
    </div>
  );
};

export default SortableItemUI;
