import React, { PureComponent } from 'react';
import { Input, Row, Button } from 'antd';
import { GridColumn } from '@progress/kendo-react-grid';

import Grid from '../../../components/grids/baseGrid';
import SetUserServicesAccessModal from './SetUserServicesAccessModal';
import CellBoolean from '../../account/cellBoolean';
import { withTranslate } from '../../../contexts/localContext';
import { ApiService } from '../../../services';
import { ALL_COLUMNS_GRID } from './constants';
import ServicesBigTableService from '../../ServiceManagement/Serivices/servicesBigTable.service';
import UserServicesAccessService from './UserServicesAccess.service';
import { lowerCaseFirstLetter } from '../../../utils/lowerCaseFirstLetter';

import './UserServicesAccess.styled.scss';
import findArray from '../../../utils/findArrForCurrentPage';
import checkElement from '../../../utils/checkElement';

const WrappedCellBoolean = (props) => <CellBoolean {...props} />;
const api = new ApiService();

class UserServicesAccess extends PureComponent {
  currentArray = findArray('users');

  state = {
    services: [],
    isLoading: false,
    selectedItems: [],
    servicesPermission: [],
    dialogType: '',
    rowAmount: '50',
    pageValue: 1,
    filtersString: '',
    sortString: '',
    hasNextPage: false,
    hasPreviousPage: false,
    isAllServicesDisabled: false,
  };

  componentDidMount() {
    this.initialFetch();
  }

  async componentDidUpdate(prevProps, prevState) {
    const { filtersString, pageValue, sortString, rowAmount, selectIdsString } = this.state;
    const {
      filtersString: prevFiltersString,
      pageValue: prevPageValue,
      sortString: prevSortString,
      rowAmount: prevRowAmont,
    } = prevState;

    if (
      pageValue !== prevPageValue ||
      sortString !== prevSortString ||
      filtersString !== prevFiltersString ||
      prevRowAmont !== rowAmount
    ) {
      if (filtersString !== prevFiltersString) {
        await this.setState({
          pageValue: 1,
        });
      }

      await this.setState({
        selectedItems: [],
      });

      await this.fetchServicesGQL();
    }
  }

  initialFetch = async () => {
    await this.fetchServicePermissions();
    await this.fetchServicesGQL();
  };

  fetchServicePermissions = async () => {
    const { userId } = this.props;
    try {
      await this.setState({ isLoading: true });
      const servicesPermission = await api.getServicesAccessList({ userId });

      this.setState({
        servicesPermission,
        isLoading: false,
        isAllServicesDisabled: servicesPermission.every(({ IsEditable }) => !IsEditable),
      });
    } catch (e) {
      this.setState({ isLoading: false });
      const { showError } = this.props;
      showError(e);
    }
  };

  iterateFetchServicesGQL = async (skip, serviceListByProps) => {
    const { filtersString, sortString } = this.state;

    try {
      const argumentsString = `take:500, skip:${skip} ,order: ${
        sortString || '{serviceId: ASC}'
      },where:{and:[{statusId:{neq:4}},${filtersString}]}`;

      const query = `services(${argumentsString}){items{${ALL_COLUMNS_GRID.join(
        ',',
      )}} pageInfo{hasNextPage, hasPreviousPage}}`;

      const data = await api.getByGraphQl(query);
      const { servicesList, hasNextPage } = ServicesBigTableService.getGQLResponse(data);

      return hasNextPage
        ? this.iterateFetchServicesGQL(skip + 500, [...serviceListByProps, ...servicesList])
        : [...serviceListByProps, ...servicesList];
    } catch (e) {
      throw e;
    }
  };

  fetchAllServicesGQL = async () => {
    try {
      await this.setState({ isLoading: true });
      const serviceList = await this.iterateFetchServicesGQL(0, []);
      const services = UserServicesAccessService.mergeServicesWithPermission(
        serviceList,
        this.state.servicesPermission,
      );
      await this.setState({ isLoading: false });

      return services;
    } catch (e) {
      const { showError } = this.props;
      showError(e);
      this.setState({ isLoading: false });
    }
  };

  fetchServicesGQL = async (props) => {
    const { rowAmount, pageValue, filtersString, sortString } = this.state;

    try {
      await this.setState({ isLoading: true });

      const argumentsString = `take:${rowAmount}, skip:${rowAmount * (pageValue - 1)} ,order: ${
        sortString || '{serviceId: ASC}'
      },where:{and:[{statusId:{neq:4}},${filtersString}]}`;

      const query = `services(${argumentsString}){items{${ALL_COLUMNS_GRID.join(
        ',',
      )}} pageInfo{hasNextPage, hasPreviousPage}}`;

      const data = await api.getByGraphQl(query);
      const { servicesList, hasNextPage, hasPreviousPage } = ServicesBigTableService.getGQLResponse(data);

      await this.setState({
        services: UserServicesAccessService.mergeServicesWithPermission(servicesList, this.state.servicesPermission),
        hasNextPage,
        hasPreviousPage,
        isLoading: false,
      });
    } catch (e) {
      const { showError } = this.props;
      await this.setState({ isLoading: false });
      showError(e);
    }
  };

  getStateSetterByName = (name) => (value) => {
    this.setState({ [name]: value });
  };

  handleRowAmountChange = (rowAmount) => {
    this.setState({
      pageValue: 1,
      rowAmount: rowAmount,
    });
  };

  onFieldsConfigChange = (list) => {
    this.fetchServicesGQL({
      currentFieldList:
        list && list.length
          ? list.map((field) => lowerCaseFirstLetter(field)).filter((field) => field !== 'selected')
          : null,
    });
  };

  getHandleExelExportDate = async () => {
    try {
      return await this.fetchAllServicesGQL();
    } catch (e) {
      const { showError } = this.props;
      showError(e);
    }
  };

  buildToolbar = () => {
    const { translate, userId } = this.props;
    const { selectedItems, isAllServicesDisabled } = this.state;

    const isPermittedSelected = selectedItems.some(({ IsEditable }) => !IsEditable);

    return (
      <Row className="UserServicesAccess-toolbar">
        <div className="UserServicesAccess-userInfo">
          <div className="UserServicesAccess-label">{translate('grids.userProperties.userId')}</div>
          <Input value={userId} disabled />
        </div>

        <div className="UserServicesAccess-actions">
          {checkElement('users-addServicesAccess', this.currentArray) && (
            <Button
              type="primary"
              disabled={!selectedItems.length || isPermittedSelected}
              onClick={() => {
                this.setState({ dialogType: 'grantAccess' });
              }}
            >
              {translate('page.userServicesAccess.grantAccess')}
            </Button>
          )}

          {checkElement('users-removeServicesAccess', this.currentArray) && (
            <Button
              type="primary"
              disabled={!selectedItems.length || isPermittedSelected || isAllServicesDisabled}
              onClick={() => {
                this.setState({ dialogType: 'cancelAccess' });
              }}
            >
              {translate('page.userServicesAccess.cancelAccess')}
            </Button>
          )}
        </div>

        {isPermittedSelected && (
          <div className="UserServicesAccess-error">{translate('page.userServicesAccess.editableError')}</div>
        )}
      </Row>
    );
  };

  render() {
    const { services, isLoading, dialogType, selectedItems, pageValue, hasNextPage, hasPreviousPage } = this.state;
    const { userId } = this.props;

    return (
      <>
        <Grid
          data={services}
          onRefresh={this.initialFetch}
          name="userServicesAccess"
          toolbar={this.buildToolbar()}
          fieldForSelection="ServiceId"
          isLoading={isLoading}
          pageValue={pageValue}
          hasNextPage={hasNextPage}
          hasPreviousPage={hasPreviousPage}
          handleRowAmountChange={this.handleRowAmountChange}
          setFiltersString={this.getStateSetterByName('filtersString')}
          pageChange={this.getStateSetterByName('pageValue')}
          setSortingString={this.getStateSetterByName('sortString')}
          onFieldsConfigChange={this.onFieldsConfigChange}
          onSelect={(selectedItems) => {
            this.setState({ selectedItems });
          }}
          getHandleExelExportDate={this.getHandleExelExportDate}
          multiSelected
          isGQL
        >
          <GridColumn
            field="selected"
            width="50px"
            filterable={false}
            sortable={false}
            showAllSelected={true}
            columnMenu={false}
          />
          <GridColumn
            title="page.userServicesAccess.accessibility"
            field="IsSccess"
            width="150px"
            filterable={false}
            sortable={false}
            columnMenu={false}
            cell={WrappedCellBoolean}
          />
          <GridColumn
            title="page.userServicesAccess.changeAccessibility"
            field="IsEditable"
            width="150px"
            filterable={false}
            sortable={false}
            columnMenu={false}
            cell={WrappedCellBoolean}
          />
          <GridColumn title="grids.servicesBigTable.column-supplier" field="SupplierName" width="150px" />
          <GridColumn title="grids.servicesBigTable.column-group" field="GroupName" width="150px" />
          <GridColumn
            title="grids.servicesBigTable.column-serviceId"
            field="ServiceId"
            width="150px"
            filter="numeric"
          />
          <GridColumn title="grids.servicesBigTable.column-name" field="Name" width="150px" />
          <GridColumn
            title="grids.servicesBigTable.column-transition"
            field="TransitService"
            cell={WrappedCellBoolean}
            width="150px"
            filter="boolean"
          />
          <GridColumn title="grids.servicesBigTable.column-original-status" field="StatusName" width="200px" />
          <GridColumn title="grids.servicesBigTable.column-baseService" field="RefId" width="150px" filter="numeric" />
          <GridColumn title="grids.servicesBigTable.column-categoryManager" field="ManagerName" width="200px" />
          <GridColumn title="grids.servicesBigTable.column-supportManager" field="SupportManagerName" width="220px" />
          <GridColumn title="grids.servicesBigTable.column-acquisitionManager" field="SalesManagerName" width="240px" />
          <GridColumn
            title="page.userServicesAccess.changeDate"
            field="DatePost"
            width="150px"
            filter="date"
            format="dd.MM.yyyy HH:mm:ss"
          />
        </Grid>

        {dialogType && (
          <SetUserServicesAccessModal
            dialogType={dialogType}
            closeModal={() => {
              this.setState({ dialogType: '' });
            }}
            selectedItems={selectedItems}
            onRefresh={this.initialFetch}
            userId={userId}
          />
        )}
      </>
    );
  }
}

export default withTranslate(UserServicesAccess);
