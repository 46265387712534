import React, { PureComponent } from 'react';
import { Layout, Col, Row, Typography } from 'antd';
import './footer.scss';

const { Footer } = Layout;
const { Text } = Typography;

export default class PageFooter extends PureComponent {
  render() {
    const lastUpdate = new Date(process.env.REACT_APP_LAST_UPDATE).toLocaleDateString('uk-UA', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    });

    return (
      <Footer style={{ padding: '0 20px 4px' }}>
        <Row type="flex" justify="end" style={{ gap: '6px' }}>
          <Col>
            <Text>© 2006 – {new Date().getFullYear()} Easypay</Text>
          </Col>
          {process.env.REACT_APP_LAST_UPDATE && (
            <Col>
              <Text>Build Version - {lastUpdate}</Text>
            </Col>
          )}
        </Row>
      </Footer>
    );
  }
}
